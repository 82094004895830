import * as angular from 'angular';
import { PermissionService } from '@services/permission.service';
import { BaseController } from 'src/app/base.controller';

class ConfigureKcviSitesComponent implements angular.IComponentOptions {
  bindings = {
    accountSites: '<',
    onChange: '&',
  };
  controller = ConfigureKcviSitesController;
  controllerAs = 'vm';
  templateUrl = 'src/app/pages/administration/views/account-details/kcvi/configureKcvi-sites.html';
}

class ConfigureKcviSitesController extends BaseController {
  private onChange: Function;
  public selectedSites = [] as fuse.siteProfileDto[];
  private unselectedSites = [] as fuse.siteProfileDto[];
  public unselectedFilteredSites = [] as fuse.siteProfileDto[];
  public searchTerm = '';
  public isAllUnselectedFilteredSitesChecked = false;
  public isAllSelectedSitesChecked = false;

  constructor(
    $scope: angular.IScope,
    PermissionService: PermissionService,
  ) {
    super(
      $scope,
      PermissionService,
    );
  }

  $onChanges(changes) {
    if (changes.accountSites?.currentValue?.length) {
      this.unselectedSites = changes.accountSites.currentValue;
      this.unselectedFilteredSites = this.unselectedSites.filter((a) => a.name.toLowerCase().includes(this.searchTerm.toLowerCase()));
      this.selectedSites = [];
    }
  }

  public selectedSitesChanged() {
    this.onChange({ selectedSites: this.selectedSites });
  }

  public pushSelectedSites(): void {
    const movedSites = this.unselectedFilteredSites.filter((a) => a.isChecked);

    if (movedSites.length) {
      this.unselectedSites = this.unselectedSites.filter((a) => movedSites.some((b) => b.name == a.name) == false);
      this.unselectedFilteredSites = this.unselectedSites.filter((a) => a.name.toLowerCase().includes(this.searchTerm.toLowerCase()));
      movedSites.forEach((a) => (a.isChecked = false));
      this.selectedSites.push(...movedSites);
      this.itemChanged();
      this.selectedSitesChanged();
    }
  }

  public popSelectedSites(): void {
    const movedSites = this.selectedSites.filter((a) => a.isChecked);
    if (movedSites.length) {
      this.selectedSites = this.selectedSites.filter((a) => a.isChecked == false);
      movedSites.forEach((a) => (a.isChecked = false));
      this.unselectedSites.push(...movedSites);
      this.unselectedFilteredSites = this.unselectedSites.filter((a) => a.name.toLowerCase().includes(this.searchTerm.toLowerCase()));
      this.itemChanged();
      this.selectedSitesChanged();
    }
  }

  public selectAllUnselected(): void {
    if (this.isAllUnselectedFilteredSitesChecked) {
      this.unselectedFilteredSites.forEach((a) => (a.isChecked = true));
    } else {
      this.unselectedFilteredSites.forEach((a) => (a.isChecked = false));
    }
    this.itemChanged();
  }

  public selectAllSelected(): void {
    if (this.isAllSelectedSitesChecked) {
      this.selectedSites.forEach((a) => (a.isChecked = true));
    } else {
      this.selectedSites.forEach((a) => (a.isChecked = false));
    }
  }

  public itemChanged(): void {
    if (this.selectedSites.length) {
      this.isAllSelectedSitesChecked = this.selectedSites.every((a) => a.isChecked);
    } else {
      this.isAllSelectedSitesChecked = false;
    }
    if (this.unselectedFilteredSites.length) {
      this.isAllUnselectedFilteredSitesChecked = this.unselectedFilteredSites.every((a) => a.isChecked);
    } else {
      this.isAllUnselectedFilteredSitesChecked = false;
    }
  }

  public searchTermChanged() {
    this.unselectedFilteredSites = this.unselectedSites.filter((a) => a.name.toLowerCase().includes(this.searchTerm.toLowerCase()));
    this.itemChanged();
  }
}

angular.module('app.administration').component('configureKcviSites', new ConfigureKcviSitesComponent());
