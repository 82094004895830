import * as angular from 'angular';
import { LocalStorageUtils } from '@indicina/swan-shared/utils/LocalStorageUtils';
import { ApplicationPrivileges } from '@common/ApplicationPrivileges';
import { EntityList } from '@common/EntityList';
import { DataEntityService } from '@services/data-entity.service';
import { FetchDataService } from '@services/fetch-data.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { Contact } from 'src/app/_DBContext/Contact';
import { BaseController } from 'src/app/base.controller';
import { ContactDialogController } from './dialogs/contact-dialog.controller';

export class ContactsController extends BaseController {
  private _mdDialog: angular.material.IDialogService;
  private _q: angular.IQService;
  private _state: angular.ui.IStateService;
  private _dataEntityService: DataEntityService;
  private _fetchDataService: FetchDataService;
  private _languageService: LanguageService;

  public entityList: EntityList<any>;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $q: angular.IQService,
    $scope: angular.IScope,
    $state: angular.ui.IStateService,
    DataEntityService: DataEntityService,
    FetchDataService: FetchDataService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
  ) {
    super(
      $scope,
      PermissionService,
    );
    this.setEditPermission(ApplicationPrivileges.ContactsFull);

    this._mdDialog = $mdDialog;
    this._q = $q;
    this._state = $state;
    this._dataEntityService = DataEntityService;
    this._fetchDataService = FetchDataService;
    this._languageService = LanguageService;

    this.entityManager = DataEntityService.manager;
  }

  public get hasDataChanges(): boolean {
    return this._dataEntityService.hasDataChanges;
  }

  $onInit() {
    this._fetchData();
  }

  private _fetchData(): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    this._fetchDataService.fetchAccountContacts(this.accountId).then((data) => {
      this.entityList = new EntityList(data as any);

      this.entityList.setTranslation(this._languageService, 'Class', 'CONTACT_TYPE');

      defer.resolve();
    })
    .catch((reason) => {
      console.log(reason);

      defer.reject(reason);
    });

    return defer.promise;
  }

  public openContactDialog(contact: Contact) {
    this._mdDialog
      .show({
        controller: ContactDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/administration/views/contacts/dialogs/contact-dialog.html',
        parent: angular.element(document.body),
        locals: {
          contact: contact,
        },
      })
      .then((res) => {
        this.entityList.updateTranslations(this._languageService);
      });
  }

  public deleteContact(contact: Contact) {
    const confirm = this._mdDialog
      .confirm()
      .title(this._languageService.instant('COMMON.CONFIRM_DELETE'))
      .htmlContent('<b>' + contact.CompanyName + '</b> ' + this._languageService.instant('COMMON.WILL_BE_DELETED'))
      .ariaLabel(this._languageService.instant('COMMON.DELETE'))
      .ok(this._languageService.instant('COMMON.YES_DELETE'))
      .cancel(this._languageService.instant('COMMON.CANCEL'));
    this._mdDialog.show(confirm).then(() => {
      const saPred: breeze.Predicate = breeze.Predicate.create('Id', '==', contact.Id);
      const querySA = breeze.EntityQuery.from('Contact').where(saPred);
      const sa: breeze.Entity[] = this.entityManager.executeQueryLocally(querySA); // query the cache (synchronous)
      for (let idx = 0; idx < sa.length; idx++) {
        sa[idx].entityAspect.setDeleted();
      }
      if (this.hasDataChanges) {
        this._dataEntityService.saveChanges()
          .then(() => {
            this._languageService.showSaveSuccess();
            this._state.reload();
          },
          (saveFailed) => {
            this._languageService.showSaveFailure(saveFailed.message);
          },
        );
      }
    });
  }

  public gotoPriceList(supId: number) {
    LocalStorageUtils.updateContextData((context) => {
      context.siteId = supId;
    });

    this._state.go('app.administration.contacts.pricelist', { id: supId });
  }
}

angular.module('app.administration').controller('ContactsController', ContactsController);
