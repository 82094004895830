import * as angular from 'angular';
import { LanguageService } from '@services/language.service';
import { NotifyEvents, NotifyingService } from '@services/notifying.service';
import { PermissionService } from '@services/permission.service';
import { SWANConstants } from '@common/SWANConstants';
import { AccountsService } from '@services/administration/accounts.service';
import { BaseController } from 'src/app/base.controller';

export class AccountDetailsDialogController extends BaseController {
  public title: string;
  public accountDetail: fuse.accountDetail;
  public countryList: string[];
  public currencyCodes: fuse.currencyCodeDto[];

  private _mdDialog: angular.material.IDialogService;
  private _accountService: AccountsService;
  private _languageService: LanguageService;
  private _notifyingService: NotifyingService;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    AccountsService: AccountsService,
    LanguageService: LanguageService,
    NotifyingService: NotifyingService,
    PermissionService: PermissionService,
    account: fuse.accountDetail,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._accountService = AccountsService;
    this._languageService = LanguageService;
    this._notifyingService = NotifyingService;

    this.title = this._languageService.instant('ADM.ACCOUNT_DETAILS.TITLE');
    this.accountDetail = angular.copy(account);
    this.countryList = [];
    this.currencyCodes = SWANConstants.currencyCodes;
    this.fetchData();
  }

  private fetchData() {
    this._accountService.getAllCountries().then((results) => {
      this.countryList = results;
    });
  }

  public saveAccount() {
    this._accountService.updateSystemAccount(this.accountDetail).then(() => {
      this._languageService.showSaveSuccess();

      this._mdDialog.hide(true).then(() => {
        this._notifyingService.notify(NotifyEvents.Admin.AccountUpdate, null);
      });
    })
    .catch((error) => {
      error.data?.ErrorMessages?.forEach((msg) => {
        this._languageService.warning(msg)
      });
    });
  }

  public closeDialog() {
    if (this.scope['accountDetailForm'].$dirty) {
      const confirm = this._languageService.closeDialog();
      this._mdDialog.show(confirm).then(() => {
        this._mdDialog.hide();
      });
    } else {
      this._mdDialog.hide();
    }
  }
}

angular.module('app.administration').controller('AccountDetailsDialogController', AccountDetailsDialogController);
