import * as angular from 'angular';
import { Asset } from 'src/app/_DBContext/Asset';
import { CurrencyISO4217 } from 'src/app/_DBContext/CurrencyISO4217';
import { Fertiliser } from 'src/app/_DBContext/Fertiliser';
import { FertiliserPrice } from 'src/app/_DBContext/FertiliserPrice';
import { DataEntityService } from '@services/data-entity.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { BaseController } from 'src/app/base.controller';
import { ApplicationPrivileges } from '@common/ApplicationPrivileges';
import { SWANConstants } from '@common/SWANConstants';

export class PricelistDialogController extends BaseController {
  private _state: angular.ui.IStateService;
  private _dataEntityService: DataEntityService;
  private _languageService: LanguageService;

  public minDate = SWANConstants.MinDate;
  public title: string;
  public theItem: FertiliserPrice;
  public newItem = false;
  public allFields = false;

  public fertiliserList = [] as Fertiliser[];
  public currencyList = [] as CurrencyISO4217[];

  public supplierId: number;
  private effectiveFromDate: Date;
  private effectiveToDate: Date;

  constructor(
    $scope: angular.IScope,
    $state: angular.ui.IStateService,
    DataEntityService: DataEntityService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    Item,
  ) {
    super(
      $scope,
      PermissionService,
    );
    this.setEditPermission(ApplicationPrivileges.ContactsPriceListFull);

    this._state = $state;
    this._dataEntityService = DataEntityService;
    this._languageService = LanguageService;

    this.entityManager = DataEntityService.manager;
    this.supplierId = $state.params.id;
    this.title = 'ADM.PRICELIST.EDIT_ITEM_PRICE';
    this.theItem = angular.copy(Item);
    this.newItem = false;
    this.allFields = false;
    this.effectiveFromDate = new Date();
    this.effectiveToDate = new Date();
    this.effectiveToDate.setDate(this.effectiveToDate.getDate() + 365);

    if (!this.theItem) {
      this.theItem = {
        EffectiveFrom: this.effectiveFromDate,
        EffectiveTo: this.effectiveToDate,
        CurrencyCode: 'AUD',
        PricePerUnit: 0.01,
        UnitWeight: 'kg',
      } as FertiliserPrice;
      this.title = 'ADM.PRICELIST.NEW_PRICE_ITEM';
      this.newItem = true;
    }

    breeze.EntityQuery.from('CurrencyISO4217').using(this.entityManager).execute().then(this.currencyISO4217SuccessCallback.bind(this));

    const pred = new breeze.Predicate('Fertiliser', breeze.FilterQueryOp.NotEquals, null)
      .and('Status', breeze.FilterQueryOp.NotEquals, 'Archived')
      .and('Fertiliser.Type', breeze.FilterQueryOp.Equals, 'Raw');
    breeze.EntityQuery.from('AccountAssets')
      .expand('Fertiliser')
      .withParameters({ accountId: this.accountId })
      .where(pred)
      .using(this.entityManager)
      .execute()
      .then((data) => {
        this.fertiliserList = [];
        data.results.map((asset: Asset) => {
          this.fertiliserList.push(asset.Fertiliser);
        });
        console.log(this.fertiliserList.length);
      });
  }

  public get hasDataChanges(): boolean {
    return this._dataEntityService.hasDataChanges;
  }

  public addNewItem() {
    if (this.theItem.AssetId != null) {
      let theName = '';
      for (let j = 0; j < this.fertiliserList.length; j++) {
        if (this.fertiliserList[j].AssetId == this.theItem.AssetId) {
          theName = this.fertiliserList[j].Asset.Name;
        }
      }
      const assetType: any = this.entityManager.metadataStore.getEntityType('FertiliserPrice');
      const newAssetRec = {
        AssetId: this.theItem.AssetId,
        AssetName: theName,
        SupplierId: this.supplierId,
        EffectiveFrom: this.theItem.EffectiveFrom,
        EffectiveTo: this.theItem.EffectiveTo,
        CurrencyCode: this.theItem.CurrencyCode,
        PricePerUnit: this.theItem.PricePerUnit,
        UnitWeight: this.theItem.UnitWeight,
        Comment: this.theItem.Comment,
      };

      const newEntry: any = assetType.createEntity(newAssetRec);

      this.entityManager.addEntity(newEntry);
      this._dataEntityService.saveChanges().then(
        () => {
          this._languageService.showSaveSuccess();
          this._state.reload();
        },
        (saveFailed) => {
          this._languageService.showSaveFailure(saveFailed.message);

          if (saveFailed.entityErrors) {
            saveFailed.entityErrors.map((error) => {
              this._languageService.info(error.errorMessage);
            });
          }
        },
      );
      this.closeDialog();
    } else {
      this._languageService.warning('ADM.PRICELIST.SELECT_EXISTING_FERTILISER');
    }
  }

  public saveItem() {
    let theName = '';
    for (let j = 0; j < this.fertiliserList.length; j++) {
      if (this.fertiliserList[j].AssetId == this.theItem.AssetId) {
        theName = this.fertiliserList[j].Asset.Name;
      }
    }
    const saPred: breeze.Predicate = breeze.Predicate.create('Id', '==', this.theItem.Id);
    const querySA = breeze.EntityQuery.from('FertiliserPrice').where(saPred);
    const sa: breeze.Entity[] = this.entityManager.executeQueryLocally(querySA); // query the cache (synchronous)
    for (let idx = 0; idx < sa.length; idx++) {
      const entity = sa[idx] as FertiliserPrice;
      entity.AssetId = this.theItem.AssetId;
      entity.AssetName = theName;
      entity.EffectiveFrom = this.theItem.EffectiveFrom;
      entity.EffectiveTo = this.theItem.EffectiveTo;
      entity.CurrencyCode = this.theItem.CurrencyCode;
      entity.PricePerUnit = this.theItem.PricePerUnit;
      entity.UnitWeight = this.theItem.UnitWeight;
      entity.Comment = this.theItem.Comment;
    }
    if (this.hasDataChanges) {
      this._dataEntityService.saveChanges().then(
        () => {
          this._languageService.showSaveSuccess();
        },
        (saveFailed) => {
          this._languageService.showSaveFailure(saveFailed);

          if (saveFailed.entityErrors) {
            saveFailed.entityErrors.map((error) => {
              this._languageService.info(error.errorMessage);
            });
          }
        },
      );
    }
    this.closeDialog();
  }

  public deleteItemConfirm(ev: MouseEvent) {
    const confirm = this._languageService
      .confirm()
      .title('ADM.PRICELIST.CONFIRM_DELETE_RECORD')
      .htmlContent('ADM.PRICELIST.ASSET_WILL_BE_DELETED', { asset: this.theItem.AssetName })
      .ariaLabel('COMMON.DELETE')
      .multiple(true)
      .targetEvent(ev)
      .ok('COMMON.OK')
      .cancel('COMMON.CANCEL');

    this._languageService.show(confirm).then(() => {
      const saPred: breeze.Predicate = breeze.Predicate.create('Id', '==', this.theItem.Id);
      const querySA = breeze.EntityQuery.from('FertiliserPrice').where(saPred);
      const sa: breeze.Entity[] = this.entityManager.executeQueryLocally(querySA); // query the cache (synchronous)
      for (let idx = 0; idx < sa.length; idx++) {
        sa[idx].entityAspect.setDeleted();
      }
      if (this.hasDataChanges) {
        this._dataEntityService.saveChanges()
          .then((saveResult) => {
            this._languageService.success('AC.SITE.MSG.CHANGED_SAVED_REFRESHING');
            this._state.reload();
          },
          (saveFailed) => {
            this._languageService.showSaveFailure(saveFailed.message);

            if (saveFailed.entityErrors) {
              saveFailed.entityErrors.map((error) => {
                this._languageService.info(error.errorMessage);
              });
            }
          },
        );
      }
    });
  }

  public closeDialog() {
    this._languageService.hide();
  }

  private currencyISO4217SuccessCallback(data: breeze.QueryResult) {
    this.currencyList = [];
    data.results.map((theData: CurrencyISO4217) => {
      this.currencyList.push(theData);
    });
  }
}

angular.module('app.administration').controller('PricelistDialogController', PricelistDialogController);
