import * as angular from 'angular';
import * as moment from 'moment';
import { Asset } from 'src/app/_DBContext/Asset';
import { FertiliserPrice } from 'src/app/_DBContext/FertiliserPrice';
import { AdminService } from '@services/admin.service';
import { DataEntityService } from '@services/data-entity.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { BaseController } from 'src/app/base.controller';
import { TableControl } from '@common/helpers/TableControl';
import { PricelistDialogController } from './dialogs/pricelist-dialog.controller';
import { CurrencyISO4217 } from 'src/app/_DBContext/CurrencyISO4217';
import { Contact } from 'src/app/_DBContext/Contact';
import { SWANConstants } from '@common/SWANConstants';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { ApplicationPrivileges } from '@common/ApplicationPrivileges';

export class PricelistController extends BaseController {
  private _state: angular.ui.IStateService;
  private _adminService: AdminService;
  private _dataEntityService: DataEntityService;
  private _languageService: LanguageService;

  public nameFilter = '';
  private fertiliserPrices = [] as FertiliserPrice[];
  public filteredFertiliserPrices = [] as FertiliserPrice[];
  private fertilisers: Asset[] = [];
  public supplierId;
  public arrCSV = [];
  public arrCSVhdr = [];
  private theContact = [];
  public supplier = '';

  public tableControl = new TableControl();
  public currencyList: CurrencyISO4217[] = [];

  public selectedItems = [];
  public toggleUpload = 0;
  public fileCSV: any;

  private _notInCurrentList = 'Not in current list';

  constructor(
    $scope: angular.IScope,
    $state: angular.ui.IStateService,
    AdminService: AdminService,
    DataEntityService: DataEntityService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
  ) {
    super(
      $scope,
      PermissionService,
    );
    this.setEditPermission(ApplicationPrivileges.ContactsPriceListFull);

    this._state = $state;
    this._adminService = AdminService;
    this._dataEntityService = DataEntityService;
    this._languageService = LanguageService;

    this.entityManager = DataEntityService.manager;
    this.supplierId = $state.params.id;

    if (AdminService.getKeepFilter() == true) {
      this.nameFilter = AdminService.getNameFilter();
      AdminService.setKeepFilter(false);
    } else {
      AdminService.setNameFilter(this.nameFilter);
    }
  }

  $onInit() {
    this._fetchData();
    this._fetchTemplate();
    this.setWatches();
  }

  private setWatches() {
    this.scope.$watchGroup(['vm.nameFilter'], () => {
      if (this.fertiliserPrices.length) {
        this.filterPrices();
      }
    });
  }

  private _fetchData() {
    const dataSuccessCallback = (data: breeze.QueryResult) => {
      this.fertiliserPrices = data.results as FertiliserPrice[];
      this.filterPrices();
    };
    breeze.EntityQuery.from('SupplierFertiliserPrices')
      .withParameters({ supplierId: this.supplierId })
      .expand('Supplier')
      .using(this.entityManager)
      .execute()
      .then(dataSuccessCallback);

    const contactSuccessCallback = (data: breeze.QueryResult) => {
      this.theContact = data.results;
      this.theContact = [];
      data.results.map((contacts: Contact) => {
        this.theContact.push(contacts);
      });

      for (let j = 0; j < this.theContact.length; j++) {
        if (this.theContact[j].Id == this.supplierId) {
          this.supplier = this.theContact[j].CompanyName;
          break;
        }
      }
    };

    breeze.EntityQuery.from('Contacts')
      .withParameters({ accountId: this.accountId })
      .using(this.entityManager)
      .execute()
      .then(contactSuccessCallback);

    const currencyListCallback = (data: breeze.QueryResult) => {
      this.currencyList = [];
      data.results.map((theData: CurrencyISO4217) => {
        this.currencyList.push(theData);
      });
    };

    breeze.EntityQuery.from('CurrencyISO4217').using(this.entityManager).execute().then(currencyListCallback);
  }

  private _fetchTemplate() {
    const successCallback = (data: breeze.QueryResult) => {
      this.fertilisers = data.results as Asset[];
      this.arrCSVhdr = [
        'AssetId',
        'Name',
        'Supplier',
        'CurrencyCode',
        'PricePerUnit',
        'Unit',
        'EffectiveFrom',
        'EffectiveTo',
        'Comment',
      ];

      for (let j = 0; j < this.fertilisers.length; j++) {
        if (
          this.fertilisers[j].AssetClassId == 14 &&
          this.fertilisers[j].Fertiliser.Type !== 'Tank Mix' &&
          this.fertilisers[j].Status !== 'Archived'
        ) {
          var SupplierId,
            PricePerUnit,
            UnitWeight = '';
          let Comment = this._notInCurrentList;
          let CurrencyCode = 'AUD';
          var EffectiveFrom,
            EffectiveTo = '';

          for (let k = 0; k < this.fertiliserPrices.length; k++) {
            if (this.fertiliserPrices[k].AssetId == this.fertilisers[j].AssetId) {
              SupplierId = this.fertiliserPrices[k].Supplier.CompanyName;
              CurrencyCode = this.fertiliserPrices[k].CurrencyCode;
              PricePerUnit = this.fertiliserPrices[k].PricePerUnit;
              UnitWeight = this.fertiliserPrices[k].UnitWeight;
              (EffectiveFrom = this.formatDate(this.fertiliserPrices[k].EffectiveFrom)),
                (EffectiveTo = this.formatDate(this.fertiliserPrices[k].EffectiveTo)),
                (Comment = this.fertiliserPrices[k].Comment);
              break;
            }
          }

          // Array with data
          this.arrCSV.push({
            AssetId: this.fertilisers[j].AssetId,
            Name: this.fertilisers[j].Name,
            SupplierId: SupplierId,
            CurrencyCode: CurrencyCode,
            PricePerUnit: PricePerUnit,
            UnitWeight: UnitWeight,
            EffectiveFrom: EffectiveFrom,
            EffectiveTo: EffectiveTo,
            Comment: Comment,
          });
        }
      }
    };

    breeze.EntityQuery.from('AccountAssets')
      .expand('Fertiliser')
      .withParameters({ accountId: this.accountId })
      .using(this.entityManager)
      .execute()
      .then(successCallback);
  }

  private isValidCurrency(currencyCode: string): boolean {
    const currency = this.currencyList.find((r) => {
      if (r.Code.toUpperCase() == currencyCode.toUpperCase()) {
        return r;
      }
    });

    const result = currency != null;

    return result;
  }

  private formatDate(date) {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  public openContactDialog(item) {
    this._languageService.show({
      controller: PricelistDialogController,
      controllerAs: 'vm',
      templateUrl: 'src/app/pages/administration/views/contacts/dialogs/pricelist-dialog.html',
      parent: angular.element(document.body),
      locals: {
        Item: item,
      },
    });
  }

  public openCsvUpload() {
    this.toggleUpload = 1; //set to 1 to display the panel
  }

  private verifyCSV(csvHeader: string) {
    const headers = csvHeader.trim().split(',');
    return (
      headers.filter((header) => {
        return header === 'AssetId';
      }).length === 1 &&
      headers.filter((header) => {
        return header === 'Name';
      }).length === 1 &&
      headers.filter((header) => {
        return header === 'Supplier';
      }).length === 1 &&
      headers.filter((header) => {
        return header === 'CurrencyCode';
      }).length === 1 &&
      headers.filter((header) => {
        return header === 'PricePerUnit';
      }).length === 1 &&
      headers.filter((header) => {
        return header === 'Unit';
      }).length === 1 &&
      headers.filter((header) => {
        return header === 'EffectiveFrom';
      }).length === 1 &&
      headers.filter((header) => {
        return header === 'EffectiveTo';
      }).length === 1 &&
      headers.filter((header) => {
        return header === 'Comment';
      }).length === 1
    );
  }

  public warning(msg, params) {
    if (params.column) params.column = this._languageService.instant(params.column);
    if (params.addendum) params.addendum = this._languageService.instant(params.addendum);

    this._languageService.warning(msg, 'COMMON.WARNING', params);
  }

  public selectCsvFile() {
    const fInput: any = document.getElementById('file');

    this.fileCSV = fInput.files[0];
  }

  public uploadCsv() {
    const fInput: any = document.getElementById('file');
    const file = fInput.files[0];
    const r = new FileReader();

    r.onloadend = (e) => {
      let csvDataRows: any[] = (e.target as any).result.split('\n');
      const csvHeader: string = csvDataRows.shift();

      if (!this.verifyCSV(csvHeader)) {
        this._languageService.warning('ADM.PRICELIST.CSV_NOT_VALID');
        return;
      }
      let isValid = true;
      let msg;
      csvDataRows = csvDataRows.map((row) => {
        const rowArray = row.split(',');
        let comment: string;
        comment = String(rowArray[8]).replace(SWANConstants.RegexRemoveDoubleQuote, '').trim();
        if (rowArray[0] != '' && isValid && comment != this._notInCurrentList) {
          const fertPriceType = this.entityManager.metadataStore.getEntityType('FertiliserPrice') as breeze.EntityType;
          const fertPriceEntity = fertPriceType.createEntity() as FertiliserPrice;
          const isValidMeasurement =
            !!SWANConstants.DefaultUnitMeasurements.filter((r) => r.toLowerCase() == String(rowArray[5]).toLowerCase()).length;

          msg = 'ADM.PRICELIST.CSV_INVALID_DATA_FOR';
          let params = {};
          if (!isValidMeasurement) {
            params = { column: 'COMMON.UNIT', value: rowArray[5], addendum: 'VALID_UNIT_MEASUREMENT' };
            this.warning(msg, params);
          }
          const isValidAssetId = CommonHelper.IsValidIntegerPositiveNumber(rowArray[0]);
          const effectiveFrom = rowArray[6];
          const effectiveTo = rowArray[7];
          if (!isValidAssetId) {
            params = { column: 'COMMON.ASSET_ID', value: rowArray[0] };
            this.warning(msg, params);
          }
          fertPriceEntity.AssetId = parseInt(rowArray[0]);
          fertPriceEntity.AssetName = rowArray[1];
          fertPriceEntity.SupplierId = parseInt(this._state.params.id.toString());
          fertPriceEntity.CurrencyCode = rowArray[3];

          const isValidCurrency = rowArray[3] && this.isValidCurrency(fertPriceEntity.CurrencyCode);
          if (!isValidCurrency) {
            params = { column: 'ADM.PRICELIST.CURRENCY_CODE', value: fertPriceEntity.CurrencyCode };
            this.warning(msg, params);
          }

          const isValidPrice = CommonHelper.IsValidPositiveNumber(rowArray[4]);
          if (!isValidPrice) {
            params = { column: 'ADM.PRICELIST.PRICE_PER_UNIT', value: rowArray[4] };
            this.warning(msg, params);
          }
          if (rowArray[4] && isValidPrice) {
            fertPriceEntity.PricePerUnit = Number(rowArray[4]);
          }

          fertPriceEntity.UnitWeight = rowArray[5];

          const isValidEffectiveFrom =
            moment(effectiveFrom, SWANConstants.CSVDateFormats).isValid() && CommonHelper.IsValidCSVDateInput(effectiveFrom);

          if (!isValidEffectiveFrom) {
            msg = 'ADM.PRICELIST.INVALID_EFFECTIVE_FROM';
            params = { effectiveFrom: effectiveFrom };
            this.warning(msg, params);
          }

          if (effectiveFrom && isValidEffectiveFrom) {
            fertPriceEntity.EffectiveFrom = moment(effectiveFrom, SWANConstants.CSVDateFormats).toDate() as Date;
          }

          const isValidEffectiveTo =
            moment(effectiveTo, SWANConstants.CSVDateFormats).isValid() && CommonHelper.IsValidCSVDateInput(effectiveTo);
          if (!isValidEffectiveTo) {
            msg = 'ADM.PRICELIST.INVALID_EFFECTIVE_TO';
            params = { effectiveTo: effectiveTo };
            this.warning(msg, params);
          }

          let isValidDateRange = false;
          if (isValidEffectiveFrom && isValidEffectiveTo) {
            const dateFrom = moment(effectiveFrom, SWANConstants.CSVDateFormats).toDate();
            const dateTo = moment(effectiveTo, SWANConstants.CSVDateFormats).toDate();

            isValidDateRange = dateFrom <= dateTo;
            if (!isValidDateRange) {
              msg = 'ADM.PRICELIST.INVALID_DATE_RANGE';
              params = { effectiveFrom: effectiveFrom, effectiveTo: effectiveTo };
              this.warning(msg, params);
            }
          }

          if (effectiveTo && isValidEffectiveTo) {
            fertPriceEntity.EffectiveTo = moment(effectiveTo, SWANConstants.CSVDateFormats).toDate() as Date;
          }

          fertPriceEntity.Comment = comment;
          isValid =
            isValidMeasurement &&
            isValidAssetId &&
            isValidEffectiveFrom &&
            isValidEffectiveTo &&
            isValidPrice &&
            isValidDateRange &&
            isValidCurrency;
          return fertPriceEntity;
        }
      });

      if (isValid) {
        const existingFertPrices = this.entityManager.getEntities('FertiliserPrice') as FertiliserPrice[];

        const hasCorrectDates = true;

        csvDataRows.forEach((fertPrice: FertiliserPrice) => {
          // as discussed with user, for the future enhancement: add new column on CSV template e.g. status to describe if the price has been uploaded (on the list).
          // at this stage, the existing designed the default comment is 'not in the current list' (if the price has never been uploaded)
          // hence, additional validation is applied to upload the price list if the comment status is not equal to "Not in current list"
          if (!angular.isUndefined(fertPrice) && fertPrice.Comment.trim() != this._notInCurrentList) {
            const foundEntity = existingFertPrices.filter((efp) => {
              return efp.AssetId === fertPrice.AssetId && efp.SupplierId === fertPrice.SupplierId;
            });

            if (!foundEntity.length) {
              this.entityManager.addEntity(fertPrice);
            } else {
              foundEntity[0].EffectiveFrom = fertPrice.EffectiveFrom;
              foundEntity[0].EffectiveTo = fertPrice.EffectiveTo;
              foundEntity[0].CurrencyCode = fertPrice.CurrencyCode;
              foundEntity[0].UnitWeight = fertPrice.UnitWeight.toUpperCase();
              foundEntity[0].PricePerUnit = parseFloat(fertPrice.PricePerUnit.toString());
              foundEntity[0].Comment = fertPrice.Comment;
            }
          }
        });

        const successCallback = (data) => {
          this._languageService.showSaveSuccess();
          this.fertiliserPrices = this.entityManager.getEntities('FertiliserPrice').filter((fp: FertiliserPrice) => {
            return fp.SupplierId == parseInt(this.supplierId.toString());
          }) as FertiliserPrice[];
          this._fetchData();
        };

        const failCallback = (saveFailed) => {
          this._languageService.showSaveFailure(saveFailed.message);

          if (saveFailed.entityErrors) {
            saveFailed.entityErrors.map((error) => {
              this._languageService.info(error.errorMessage);
            });
          }
        };

        this._dataEntityService.saveChanges(true).then(successCallback, failCallback);
      } else {
        this._languageService.warning('ADM.PRICELIST.CSV_NOT_VALID');
      }
    };
    r.readAsText(file);
  }

  private filterPrices(): void {
    this._adminService.setNameFilter(this.nameFilter);
    if (this.fertiliserPrices) {
      this.filteredFertiliserPrices = this.fertiliserPrices.filter(
        (a) => a.AssetName.toLowerCase().indexOf(this.nameFilter.toLowerCase()) > -1,
      );
    }
  }

  public clearFilter(): void {
    this.nameFilter = '';
  }
}

angular.module('app.administration').controller('PricelistController', PricelistController);
