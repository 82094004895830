// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#configure-sites-kcvi-dialog {
  width: 800px;
}
#configure-sites-kcvi-dialog md-dialog-content {
  padding: 12px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/administration/views/account-details/kcvi/configureSitesKcvi-dialog.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;AAAI;EACI,aAAA;AAER","sourcesContent":["#configure-sites-kcvi-dialog {\r\n    width: 800px;\r\n    md-dialog-content{\r\n        padding: 12px;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
