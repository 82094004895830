import * as angular from 'angular';
import { ApplicationPrivileges } from '@common/ApplicationPrivileges';
import { DataEntityService } from '@services/data-entity.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { Contact } from 'src/app/_DBContext/Contact';
import { MarketRegions } from 'src/app/_DBContext/MarketRegions';
import { BaseController } from 'src/app/base.controller';

export class ContactDialogController extends BaseController {
  private _mdDialog: angular.material.IDialogService;
  private _state: angular.ui.IStateService;
  private _dataEntityService: DataEntityService;
  private _languageService: LanguageService;

  public title: string;
  public contact: Contact;
  public newContact = false;
  public allFields = false;

  public countryList = [];

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    $state: angular.ui.IStateService,
    DataEntityService: DataEntityService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    contact: Contact,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this.setEditPermission(ApplicationPrivileges.ContactsFull);

    this._mdDialog = $mdDialog;
    this._state = $state;
    this._dataEntityService = DataEntityService;
    this._languageService = LanguageService;

    this.entityManager = DataEntityService.manager;
    this.title = this._languageService.instant('ADM.CONTACTS.EDIT');
    this.contact = angular.copy(contact);
    this.newContact = false;
    this.allFields = false;

    if (!this.contact) {
      this.contact = {
        Class: 'Supplier',
        Country: 'Australia',
      } as Contact;

      this.title = this._languageService.instant('ADM.CONTACTS.ADD_NEW');
      this.newContact = true;
    }

    breeze.EntityQuery.from('MarketRegions')
      .select('Id, Country')
      .using(this.entityManager)
      .execute()
      .then(this.marketRegionsSuccessCallback.bind(this));
  }

  public get hasDataChanges(): boolean {
    return this._dataEntityService.hasDataChanges;
  }

  public addNewContact() {
    if (this.contact.CompanyName != '') {
      const assetType: any = this.entityManager.metadataStore.getEntityType('Contact');
      const newAssetRec = {
        AuthAccountId: this.accountId,
        Shared: false,
        CompanyName: this.contact.CompanyName,
        Class: this.contact.Class,
        Address: this.contact.Address,
        Suburb: this.contact.Suburb,
        Province: this.contact.Province,
        Country: this.contact.Country,
        PostCode: this.contact.PostCode,
        PersonName: this.contact.PersonName,
        PersonTitle: this.contact.PersonTitle,
        Email: this.contact.Email,
        Phone: this.contact.Phone,
        Website: this.contact.Website,
        Notes: this.contact.Notes,
      };

      const newEntry: any = assetType.createEntity(newAssetRec);
      this.entityManager.addEntity(newEntry);
      this._dataEntityService.saveChanges().then(
        () => {
          this._languageService.showSaveSuccess();
          this._state.reload();
        },
        (saveFailed) => {
          this._languageService.showSaveFailure(saveFailed.message);

          if (saveFailed.entityErrors) {
            saveFailed.entityErrors.map((error) => {
              this._languageService.info(error.errorMessage);
            });
          }
        },
      );
      this.closeDialog();
    } else {
      this._languageService.warning('ADM.CONTACTS.COMPANY_MISSING');
    }
  }

  public saveContact() {
    const saPred: breeze.Predicate = breeze.Predicate.create('Id', '==', this.contact.Id);
    const querySA = breeze.EntityQuery.from('Contact').where(saPred);
    const sa: breeze.Entity[] = this.entityManager.executeQueryLocally(querySA); // query the cache (synchronous)

    for (let idx = 0; idx < sa.length; idx++) {
      const entity = sa[idx] as Contact;

      entity.CompanyName = this.contact.CompanyName;
      entity.Class = this.contact.Class;
      entity.Address = this.contact.Address;
      entity.Suburb = this.contact.Suburb;
      entity.Province = this.contact.Province;
      entity.Country = this.contact.Country;
      entity.PostCode = this.contact.PostCode;
      entity.PersonName = this.contact.PersonName;
      entity.PersonTitle = this.contact.PersonTitle;
      entity.Email = this.contact.Email;
      entity.Phone = this.contact.Phone;
      entity.Website = this.contact.Website;
      entity.Notes = this.contact.Notes;
    }

    if (this.hasDataChanges) {
      this._dataEntityService.saveChanges().then(
        () => {
          this._languageService.showSaveSuccess();
        },
        (saveFailed) => {
          this._languageService.showSaveFailure(saveFailed.message);

          if (saveFailed.entityErrors) {
            saveFailed.entityErrors.map((error) => {
              this._languageService.info(error.errorMessage);
            });
          }
        },
      );
    }
    this.closeDialog();
  }

  public closeDialog() {
    this._mdDialog.hide();
  }

  private marketRegionsSuccessCallback(data: breeze.QueryResult) {
    this.countryList = data.results;
    this.countryList = [];
    data.results.map((marketRegions: MarketRegions) => {
      this.countryList.push(marketRegions);
    });
    // Group By Country name
    const tempCountryList = [];
    this.countryList.reduce((res, value) => {
      if (!res[value.Country]) {
        res[value.Country] = {
          country: value,
        };
        tempCountryList.push(res[value.Country]);
      }
      res[value.Country].country = value.Country;
      return res;
    }, {});
    this.countryList = tempCountryList;
  }
}

angular.module('app.administration').controller('ContactDialogController', ContactDialogController);
