// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-user-dialog md-input-container {
  margin: 0 0 8px;
}
#new-user-dialog md-input-container .md-errors-spacer {
  min-height: 0px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/administration/views/account-users/new-account-user-dialog.scss"],"names":[],"mappings":"AACI;EACI,eAAA;AAAR;AACQ;EACI,eAAA;AACZ","sourcesContent":["#new-user-dialog {\r\n    md-input-container {\r\n        margin: 0 0 8px;\r\n        .md-errors-spacer {\r\n            min-height: 0px;\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
