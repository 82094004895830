// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#data-capture-dialog {
  max-width: 1000px;
  max-height: 700px;
}
#data-capture-dialog md-dialog-content {
  padding: 12px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/administration/views/account-details/data-capture/data-capture-dialog.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,iBAAA;AACJ;AAAI;EACI,aAAA;AAER","sourcesContent":["#data-capture-dialog {\r\n    max-width: 1000px;\r\n    max-height: 700px;\r\n    md-dialog-content{\r\n        padding: 12px;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
