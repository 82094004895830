import * as angular from 'angular';
import { TableControl } from '@common/helpers/TableControl';
import { NotifyEvents, NotifyingService } from '@services/notifying.service';
import { UsersService } from '@services/administration/users.service';
import { UserDialogController } from './user-dialog.controller';

export class UsersController {
  private _usersService: UsersService;
  private _mdDialog: angular.material.IDialogService;
  private _notifyingService: NotifyingService;
  public contacts = [] as fuse.ApplicationUser[];
  public filteredContacts = [] as fuse.ApplicationUser[];

  private showDisabled = false;
  public tableControl = new TableControl({ searchField: 'Users.FirstName', pageSize: 10 });

  constructor($mdDialog: angular.material.IDialogService, UsersService: UsersService, NotifyingService: NotifyingService) {
    this._mdDialog = $mdDialog;
    this._usersService = UsersService;
    this._notifyingService = NotifyingService;
  }

  $onInit() {
    this._fetchData();
  }

  private _fetchData() {
    this._usersService.getApplicationUsers().then((results) => {
      this.contactSuccessCallback(results);
    });
  }

  private contactSuccessCallback(data: fuse.ApplicationUser[]) {
    this.contacts = data;
    this.applyDisableFilter();
  }

  public applyDisableFilter() {
    if (!this.showDisabled) {
      this.filteredContacts = this.contacts.filter((a) => a.UserAccountStatus == 'Active');
    } else {
      this.filteredContacts = this.contacts;
    }
  }

  public openContactDialog(contact: fuse.ApplicationUser) {
    this._mdDialog
      .show({
        controller: UserDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/administration/views/system/users/user-dialog.html',
        parent: angular.element(document.body),
        locals: {
          contact: contact,
        },
      })
      .then((res) => {
        if (res) {
          this._fetchData(); //refresh the list of users
          this._notifyingService.notify(NotifyEvents.Admin.UserUpdate, null);
        }
      });
  }
}

angular.module('app.administration').controller('UsersController', UsersController);
