import * as angular from 'angular';
import { SWANConstants } from '@common/SWANConstants';
import { AccountsService } from '@services/administration/accounts.service';

export class UserAccountDialogController implements angular.IController {
  private contact: fuse.ApplicationUser;
  private account: fuse.ApplicationAccountAccess;
  private allAccounts: fuse.ApplicationAccountBasic[];

  public filteredAccounts: fuse.ApplicationAccountBasic[];
  public currentAccount: fuse.ApplicationAccountAccess;
  public selectedRole: string;

  private secureRoles: string[];
  private addedAccountIDs;

  private _mdDialog: angular.material.IDialogService;
  private _accountsService: AccountsService;

  constructor(
    $mdDialog: angular.material.IDialogService,
    AccountsService: AccountsService,
    contact: fuse.ApplicationUser,
  ) {
    this._mdDialog = $mdDialog;
    this._accountsService = AccountsService;

    this.contact = angular.copy(contact);
    this.selectedRole = 'Viewer';

    this.account = {
      AuthAccountId: 0,
      AuthAccountClassId: 0,
    } as fuse.ApplicationAccountAccess;
    this.secureRoles = SWANConstants.UserRoles; //  SecureRole;  // KC TODO change to new security roles

    // list of already added account Ids for the user
    this.addedAccountIDs = [];
    this.addedAccountIDs = this.contact.UserAccountAccess.map((uaa) => uaa.AuthAccountId);

    this.fetchData();
  }

  private fetchData() {
    this._accountsService.getAllAccounts().then((results) => {
      this.allAccounts = results;
      this.setFilteredAccounts(); // filter account list by already added account Ids
    });
  }

  private setFilteredAccounts() {
    this.filteredAccounts = this.allAccounts.filter((acc) => {
      return this.addedAccountIDs.indexOf(acc.AuthAccountId) === -1 && acc.Status === 'Active';
    });
  }

  public addNewAccountAuthorisation() {
    this.account = {
      AuthAccountId: this.currentAccount.AuthAccountId,
      AuthAccountClassId: this.currentAccount.AuthAccountClassId,
      CompanyName: this.currentAccount.CompanyName,
      Status: this.currentAccount.Status,
      Role: this.selectedRole,
    } as fuse.ApplicationAccountAccess;
    this._mdDialog.hide(this.account);
  }

  public closeDialog() {
    this._mdDialog.hide();
  }

  public getRoles(accountId: number) {
    if (accountId === 1) {
      return this.secureRoles;
    }
    return SWANConstants.AccountRoles;
  }
}

angular.module('app.administration').controller('UserAccountDialogController', UserAccountDialogController);
