import * as angular from 'angular';
import { ApplicationPrivileges } from '@common/ApplicationPrivileges';
import { AccountDetailsController } from './views/account-details/account-details.controller';
import { AccountUsersController } from './views/account-users/account-users.controller';
import { ContactsController } from './views/contacts/contacts.controller';
import { PricelistController } from './views/contacts/pricelist.controller';
import { DataAgentController } from './views/references/dataagents/dataagent.controller';
import { RolePrivilegeController } from './views/system/role_privilege/role_privilege.controller';
import { SubscriptionPrivilegeController } from './views/system/subscription_privilege/subscription_privilege.controller';
import { AccountsController } from './views/system/accounts/accounts.controller';
import { UsersController } from './views/system/users/users.controller';

const administrationConfig = (
  $stateProvider: angular.ui.IStateProvider,
) => {
  $stateProvider.state('app.administration', {
    abstract: true,
    url: '/settings',
  });

  $stateProvider.state('app.administration.account-users', {
    url: '/account-users',
    views: {
      'content@app': {
        templateUrl: 'src/app/pages/administration/views/account-users/account-users.html',
        controller: AccountUsersController,
        controllerAs: 'vm',
      },
    },
    data: { permission: ApplicationPrivileges.AdminUsersView },
  });

  $stateProvider.state('app.administration.account-details', {
    url: '/account-details',
    views: {
      'content@app': {
        templateUrl: 'src/app/pages/administration/views/account-details/account-details.html',
        controller: AccountDetailsController,
        controllerAs: 'vm'
      },
    },
    data: {
      permissions: [ApplicationPrivileges.AdminAccountView, ApplicationPrivileges.AdminAccountTimingsView],
    },
  });

  $stateProvider.state('app.administration.contacts', {
    url: '/contacts',
    views: {
      'content@app': {
        templateUrl: 'src/app/pages/administration/views/contacts/contacts.html',
        controller: ContactsController,
        controllerAs: 'vm',
      },
    },
    data: { permission: ApplicationPrivileges.ContactsView },
    resolve: {},
  });

  $stateProvider.state('app.administration.contacts.pricelist', {
    url: '/pricelist/:id',
    views: {
      'content@app': {
        templateUrl: 'src/app/pages/administration/views/contacts/pricelist.html',
        controller: PricelistController,
        controllerAs: 'vm',
      },
    },
    data: { permission: ApplicationPrivileges.ContactsPriceListView },
  });

  $stateProvider.state('app.administration.data-agents', {
    url: '/data-agent',
    views: {
      'content@app': {
        templateUrl: 'src/app/pages/administration/views/references/dataagents/dataagent.html',
        controller: DataAgentController,
        controllerAs: 'vm',
      },
    },
    data: { permission: ApplicationPrivileges.AdminSystemAccountsView },
  });

  $stateProvider.state('app.administration.system-accounts', {
    url: '/system-accounts',
    views: {
      'content@app': {
        templateUrl: 'src/app/pages/administration/views/system/accounts/accounts.html',
        controller: AccountsController,
        controllerAs: 'vm',
      },
    },
    data: { permission: ApplicationPrivileges.AdminAccountView },
  });

  $stateProvider.state('app.administration.system-users', {
    url: '/system-users',
    views: {
      'content@app': {
        templateUrl: 'src/app/pages/administration/views/system/users/users.html',
        controller: UsersController,
        controllerAs: 'vm',
      },
    },
    data: { permission: ApplicationPrivileges.AdminUsersView },
  });

  $stateProvider.state('app.administration.roleprivilege', {
    url: '/role-privilege',
    views: {
      'content@app': {
        templateUrl: 'src/app/pages/administration/views/system/role_privilege/role_privilege.html',
        controller: RolePrivilegeController,
        controllerAs: 'vm',
      },
    },
    data: { permission: ApplicationPrivileges.AdminSystemAccountsView },
  });

  $stateProvider.state('app.administration.subscriptionprivilege', {
    url: '/subscription-privilege',
    views: {
      'content@app': {
        templateUrl: 'src/app/pages/administration/views/system/subscription_privilege/subscription_privilege.html',
        controller: SubscriptionPrivilegeController,
        controllerAs: 'vm',
      },
    },
    data: { permission: ApplicationPrivileges.AdminSystemAccountsView },
  });
}

angular.module('app.administration').config(['$stateProvider', administrationConfig]);
