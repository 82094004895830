import * as angular from 'angular';
import { PermissionService } from '@services/permission.service';
import { BaseController } from 'src/app/base.controller';

export class BasicDialogController extends BaseController {
  public title: string;
  public body: string;

  //** Basic, generic pop-up template, featuring title and body text. Both may contain some html and/or translation tags. */
  private _mdDialog: angular.material.IDialogService;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    PermissionService: PermissionService,
    title: string,
    body: string,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;

    this.title = title;
    this.body = body;
  }

  public close() {
    this._mdDialog.hide();
  }
}

angular.module('app.administration').controller('BasicDialogController', BasicDialogController);
