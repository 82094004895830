import * as angular from 'angular';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { BaseController } from 'src/app/base.controller';

export class ConfigureSitesKcviDialogController extends BaseController {
  private _http: angular.IHttpService;
  private _mdDialog: angular.material.IDialogService;
  private _languageService: LanguageService;

  public accountSites: fuse.siteProfileDto[] = [];
  public step = 0;
  public selectedSites = [] as fuse.siteProfileDto[];
  public kcviOptions = {} as fuse.accountKcviOptionsDto;
  public isKcviOptionsValid = false;

  constructor(
    $http: angular.IHttpService,
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._http = $http;
    this._mdDialog = $mdDialog;
    this._languageService = LanguageService;
  }

  $onInit() {
    this._http.get(CommonHelper.getApiUrl('administration/getAccountSiteProfiles')).then((result) => {
      this.accountSites = result.data as fuse.siteProfileDto[];
    });
  }

  public closeDialog() {
    this._mdDialog.hide();
  }

  public onSelectedSitesChanged(selectedSites: fuse.siteProfileDto[]): void {
    this.selectedSites = selectedSites;
  }

  public back(): void {
    this.step--;
  }

  public next(): void {
    this.step++;
  }

  public onKcviOptionsChanged(kcviOptions: fuse.accountKcviOptionsDto): void {
    this.kcviOptions = kcviOptions;
    this.isKcviOptionsValid = true;
    if (!this.kcviOptions.isDataSourceChecked && !this.kcviOptions.isCalculationChecked && !this.kcviOptions.isKcviUsedInCropChecked) {
      this.isKcviOptionsValid = false;
    }
    if (this.kcviOptions.isDataSourceChecked) {
      if (this.kcviOptions.imageryProviderId == null) {
        this.isKcviOptionsValid = false;
      }
    }
    if (this.kcviOptions.isCalculationChecked) {
      if (this.kcviOptions.algorithmId == null) {
        this.isKcviOptionsValid = false;
      } else if (this.kcviOptions.algorithmFromDate == null) {
        this.isKcviOptionsValid = false;
      } else if (this.kcviOptions.indexValue == null) {
        this.isKcviOptionsValid = false;
      } else if (this.kcviOptions.isReplaceSubsequentAlgorithms == null) {
        this.isKcviOptionsValid = false;
      } else if (this.kcviOptions.resultMultiplier == null) {
        this.isKcviOptionsValid = false;
      }
    }
    if (this.kcviOptions.isKcviUsedInCropChecked) {
      if (this.kcviOptions.isDeleteOverlappedCropTime == null) {
        this.isKcviOptionsValid = false;
      }
      if (
        !this.kcviOptions.kcviCropTimes.length ||
        this.kcviOptions.kcviCropTimes.some((a) => a.fromLocaleDate == null || a.endLocaleDate == null)
      ) {
        this.isKcviOptionsValid = false;
      }
    }
  }

  public apply() {
    if (this.kcviOptions.isKcviUsedInCropChecked) {
      if (this.kcviOptions.kcviCropTimes.length > 1) {
        for (let i = 0; i < this.kcviOptions.kcviCropTimes.length - 1; i++) {
          const cropTime1 = this.kcviOptions.kcviCropTimes[i];
          for (let j = i + 1; j < this.kcviOptions.kcviCropTimes.length; j++) {
            const cropTime2 = this.kcviOptions.kcviCropTimes[j];
            for (let date = cropTime1.fromLocaleDate.clone(); date <= cropTime1.endLocaleDate; date = date.addDays(1)) {
              if (cropTime2.fromLocaleDate <= date && date <= cropTime2.endLocaleDate) {
                this._languageService.error('ADM.ACCOUNT_DETAILS.KCVI_OVERLAP');
                return;
              }
            }
          }
        }
      }
    }
    this.kcviOptions.siteIds = this.selectedSites.map((a) => a.siteId);
    if (this.kcviOptions.isCalculationChecked) {
      this.kcviOptions.algorithmFromDateYMD = this.kcviOptions.algorithmFromDate.toString('yyyy-MM-dd');
    }
    if (this.kcviOptions.isKcviUsedInCropChecked) {
      this.kcviOptions.kcviCropTimes.forEach((croptime) => {
        croptime.fromDateYMD = croptime.fromLocaleDate.toString('yyyy-MM-dd');
        croptime.endDateYMD = croptime.endLocaleDate.toString('yyyy-MM-dd');
      });
    }
    this._http.post(CommonHelper.getApiUrl('administration/updateAccountSitesKcvi'), this.kcviOptions).then((result) => {
      this._mdDialog.hide();
      this._languageService.showSaveSuccess();
    });
  }
}

angular.module('app.administration').controller('ConfigureSitesKcviDialogController', ConfigureSitesKcviDialogController);
