import * as angular from 'angular';
import { DateUtils } from '@indicina/swan-shared/utils/DateUtils';
import { ApplicationPrivileges } from '@common/ApplicationPrivileges';
import { SWANConstants } from '@common/SWANConstants';
import { ApplicationSubscriptions } from '@common/enums';
import { LanguageService } from '@services/language.service';
import { NotifyEvents, NotifyingService } from '@services/notifying.service';
import { PermissionService } from '@services/permission.service';
import { AccountsService } from '@services/administration/accounts.service';
import { BaseController } from 'src/app/base.controller';

export class AccountDialogController extends BaseController {
  public title: string;
  public accountDetail: fuse.accountDetail;
  public newAccount = false;
  public timeZoneLookup: fuse.SystemTimeZone[];
  public countryList: string[];
  public accountStatus: string[]; //lookup
  public subscriptionLookup: fuse.subscriptionDto[]; //lookup
  public stylingCategories: fuse.stylingCategory[];
  public hourPickList = DateUtils.Locale.getHours24Format();
  public minutePickList = ['00'];
  private localStartTimeHour: string;
  private localStartTimeMin = '00';
  private irrigationSeasonStartMonth: number;
  public shortMonthNames: string[] = DateUtils.Locale.getMonthNamesShort();

  public regexShortName: RegExp;
  public createdDate: Date;
  public commissionedDate: Date;
  public maxDate: Date;
  public dateCleared: boolean = false;

  private _mdDialog: angular.material.IDialogService;
  private _accountsService: AccountsService;
  private _languageService: LanguageService;
  private _notifyingService: NotifyingService;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    AccountsService: AccountsService,
    NotifyingService: NotifyingService,
    PermissionService: PermissionService,
    LanguageService: LanguageService,
    account: fuse.accountDetail,
  ) {
    super(
      $scope,
      PermissionService,
    );
    this.setEditPermission(ApplicationPrivileges.AdminSystemAccountsFull);

    this._mdDialog = $mdDialog;
    this._accountsService = AccountsService;
    this._languageService = LanguageService;
    this._notifyingService = NotifyingService;

    this.regexShortName = /^[A-Z|a-z|0-9]+$/;

    // Data
    this.title = this._languageService.instant('ADM.ACCOUNT_DETAILS.TITLE');

    this.accountDetail = angular.copy(account);
    this.newAccount = false;
    this.accountStatus = SWANConstants.AccountStatuses;
    this.timeZoneLookup = [];
    this.countryList = [];
    this.subscriptionLookup = [];

    if (this.accountDetail?.createdDate != null) {
      this.createdDate = new Date(this.accountDetail.createdDate.toString());
    }
    if (this.accountDetail?.commissionedDate != null) this.commissionedDate = new Date(this.accountDetail.commissionedDate.toString());
    this.maxDate = new Date();

    if (!this.accountDetail) {
      this.accountDetail = {
        authAccountClassId: 2,
        defaultReportingUnits: SWANConstants.DefaultReportingUnits,
        irrigationSeasonStartMonth: null,
        status: 'Active',
        subscriptions: [
          { subscriptionId: ApplicationSubscriptions.General, name: 'General' },
          { subscriptionId: ApplicationSubscriptions.Health, name: 'Health' },
          { subscriptionId: ApplicationSubscriptions.IrrigationOptimise, name: 'Irrigation Optimise' },
          { subscriptionId: ApplicationSubscriptions.Nutrients, name: 'Nutrients' },
          { subscriptionId: ApplicationSubscriptions.Water, name: 'Water' },
        ],
      } as fuse.accountDetail;
      this.createdDate = new Date();

      this.title = this._languageService.instant('ADM.ACCOUNT_DETAILS.TITLE_ADD');
      this.newAccount = true;
    } else {
      const starttime = this.accountDetail.irrigationLocalStartTime;
      this.localStartTimeHour = starttime.substring(0, 2);
      this.localStartTimeMin = starttime.substring(3, 5);
    }
    this.irrigationSeasonStartMonth = this.accountDetail.irrigationSeasonStartMonth == 0 ? 7 : this.accountDetail.irrigationSeasonStartMonth;
    this.fetchData();
  }

  private fetchData() {
    this._accountsService.getAllTimeZones().then((results) => {
      this.timeZoneLookup = results;
    });
    this._accountsService.getAllCountries().then((results) => {
      this.countryList = results;
    });
    this._accountsService.getAllSubscriptions().then((results) => {
      this.subscriptionLookup = results;
    });
    this._accountsService.getStylingCategories().then((results) => {
      this.stylingCategories = results;
    });

    if (this.accountDetail.authAccountId) {
      this._accountsService.getSystemAccountById(this.accountDetail.authAccountId).then((result) => {
        if (!result.country) {
          result.country = SWANConstants.DefaultCountry;
        }
        this.accountDetail = result;
      });
    }
  }

  public clearCreatedDate() {
    if (this.createdDate == null) return;

    this.createdDate = null;
    this.dateCleared = true;
  }

  public clearCommissionedDate() {
    if (this.commissionedDate == null) return;

    this.commissionedDate = null;
    this.dateCleared = true;
  }

  public hasSubscription(subscriptionName: string): boolean {
    if (this.account) {
      return this.accountDetail.subscriptions.some((a) => a.name == subscriptionName);
    } else {
      return false;
    }
  }

  public setSubscription(subscription: fuse.subscriptionDto) {
    if (subscription.name == 'General') {
      this._languageService.warning('ADM.ACCOUNT_DETAILS.GENERAL_MODULE_MANDATORY');
      return;
    }
    if (this.hasSubscription(subscription.name)) {
      // remove it using filter not equal
      this.accountDetail.subscriptions = this.accountDetail.subscriptions.filter((a) => a.name != subscription.name);
      this.scope['accountForm'].$setDirty();
    } else {
      this.accountDetail.subscriptions.push(subscription);
      this.scope['accountForm'].$setDirty();
    }
  }

  public addNewAccount() {
    if (
      this.accountDetail.companyName &&
      this.accountDetail.businessName &&
      this.accountDetail.businessAddress &&
      this.accountDetail.accountTenantId &&
      this.accountDetail.primaryContactName &&
      this.accountDetail.primaryContactPhone &&
      this.accountDetail.primaryContactEmail
    ) {
      this.accountDetail.irrigationLocalStartTime = this.localStartTimeHour + ':' + this.localStartTimeMin + ':00';
      this.accountDetail.irrigationSeasonStartMonth = this.irrigationSeasonStartMonth;
      this.accountDetail.createdDate = new Date();
      this._accountsService
        .createSystemAccount(this.accountDetail)
        .then(() => {
          this.permissionService.reloadAvailableAccounts();
          this._languageService.showSaveSuccess();
          this._mdDialog.hide({ dataRefreshRequired: true }).then(() => {
            this._notifyingService.notify(NotifyEvents.Admin.AccountUpdate, null);
            this.notifyNewAccountAccess();
          });
        })
        .catch((error) => {
          error.data?.ErrorMessages?.forEach((msg) => {
            this._languageService.warning(msg)
          });
        })
        .finally(() => {});
    } else {
      this._languageService.error('ADM.ACCOUNT_DETAILS.CANNOT_BE_BLANK');
    }
  }

  public notifyNewAccountAccess() {
    const confirm = this._mdDialog
      .confirm()
      .title(this._languageService.instant('ADM.ACCOUNT_DETAILS.ACCESS_CHANGED'))
      .htmlContent(this._languageService.instant('ADM.ACCOUNT_DETAILS.ACCESS_CHANGED_MSG'))
      .multiple(true)
      .ok('OK');
    this._mdDialog.show(confirm);
  }

  public saveAccount() {
    this.accountDetail.irrigationLocalStartTime = this.localStartTimeHour + ':' + this.localStartTimeMin + ':00';
    this.accountDetail.commissionedDate = this.commissionedDate;
    this.accountDetail.createdDate = this.createdDate;

    this._accountsService
      .updateSystemAccount(this.accountDetail)
      .then((results) => {
        this.permissionService.reloadAvailableAccounts();
        this._languageService.showSaveSuccess();
        this._mdDialog.hide({ dataRefreshRequired: true }).then(() => {
          this._notifyingService.notify(NotifyEvents.Admin.AccountUpdate, null);
        });
      })
      .catch((error) => {
        if (error.status == 409) {
          this._languageService.error('ADM.ACCOUNT_DETAILS.NAME_TAKEN');
        } else {
          error.data?.ErrorMessages?.forEach((msg) => {
            this._languageService.warning(msg)
          });
        }
      })
      .finally(() => {});
  }

  public closeDialog() {
    if (this.scope['accountForm'].$dirty) {
      const confirm = this._languageService.closeDialog();
      this._mdDialog.show(confirm).then(
        () => {
          this._mdDialog.hide({ dataRefreshRequired: false });
        },
        () => {
          // user canceled the close - don't close the form
        },
      );
    } else {
      this._mdDialog.hide({ dataRefreshRequired: false });
    }
  }
}

angular.module('app.administration').controller('AccountDialogController', AccountDialogController);
