import * as angular from 'angular';
import { AppSettings } from '@indicina/swan-shared/AppSettings';
import { HttpUtils } from '@indicina/swan-shared/utils/HttpUtils';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { PropertyValue } from 'src/app/_DBContext/PropertyValue';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { SensorPropertyService } from '@services/sensor-property.service';
import { BaseController } from 'src/app/base.controller';

export class CollectorProfileDialogController extends BaseController {
  public workAgentCollectors: fuse.workAgentCollectorDto[];
  public collectorProperties: PropertyValue[];
  public isNameExist = false;
  public dataCollector: fuse.dataCollectorProfileDto;
  public isEdit = false;
  public lastSelected: fuse.workAgentCollectorDto = null;
  public showSchedule = false;
  public scheduler = {} as fuse.equipmentScheduler;
  public schedulerRefreshCount = 0;
  public sensorProperties = [];
  public isOAuth: boolean = false;
  public isAuthorised: boolean = false; // checks if OAuth collector has been authorised

  private _http: angular.IHttpService;
  private _mdDialog: angular.material.IDialogService;
  private _languageService: LanguageService;
  private _sensorPropertyService: SensorPropertyService;

  private _dataCollectors: fuse.dataCollectorProfileDto[];

  constructor(
    $http: angular.IHttpService,
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    SensorPropertyService: SensorPropertyService,
    dataCollector: fuse.dataCollectorProfileDto,
    dataCollectors: fuse.dataCollectorProfileDto[],
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._http = $http;
    this._mdDialog = $mdDialog;
    this._languageService = LanguageService;
    this._sensorPropertyService = SensorPropertyService;

    this.updateScheduler = this.updateScheduler.bind(this);

    this.dataCollector = angular.copy(dataCollector);
    this._dataCollectors = dataCollectors;

    this.isEdit = this.dataCollector != null;

    if (!this.isEdit) {
      this.dataCollector = {} as fuse.dataCollectorProfileDto;
    }
  }

  $onInit() {
    this.getWorkAgentCollectors();
  }

  public onWorkAgentCollectorSelected() {
    if (this.dataCollector.workAgentCollectorId !== undefined) {
      const wac = this.workAgentCollectors?.find((wac) => wac.id == this.dataCollector?.workAgentCollectorId);
      if (!this.dataCollector.name || this.dataCollector.name == this.lastSelected?.name) this.dataCollector.name = wac?.name;
      this.lastSelected = wac;
      this.checkOAuthStatus(wac.dataProviderId);
      this._sensorPropertyService
        .loadCollectorProperties(this.dataCollector, this.dataCollector.propertyValues, this.dataCollector.id, false)
        .then((result) => {
          this.collectorProperties = result as PropertyValue[];
        });
    }
  }

  private getWorkAgentCollectors() {
    this._http
      .get(CommonHelper.getApiUrl('administration/getWorkAgentCollectors'))
      .then((response) => {
        this.workAgentCollectors = response.data as fuse.workAgentCollectorDto[];
        this.onWorkAgentCollectorSelected();
      })
      .catch(() => {
        this._languageService.whoops();
      });
  }

  private checkOAuthStatus(workAgentId: number) {
    this._http
      .get(CommonHelper.getApiUrl('administration/getOAuthValues/' + workAgentId))
      .then((response) => {
        const data = response.data as fuse.oAuthStatusDto;
        this.isOAuth = data.isOAuth;
        this.isAuthorised = data.isAuthorised;
      })
      .catch(() => {
        this._languageService.whoops();
      });
  }

  public save() {
    const route = this.isEdit ? 'administration/updateDataCollector' : 'administration/addDataCollector';
    let isValid = true as boolean;
    if (this.collectorProperties?.length) {
      this.dataCollector.propertyValues = [];
      this.collectorProperties.forEach((prop) => {
        if (prop.fuseProp.required && (prop.Value == null || prop.Value == '')) {
          isValid = false;
        }
        this.dataCollector.propertyValues.push({
          id: prop.Id,
          propertyId: prop.PropertyId,
          property: prop.fuseProp,
          value: prop.Value,
        });
      });
    }
    if (isValid == false) {
      this._languageService.error('COMMON.MANDATORY_MISSING');
      return;
    }
    this._http.post(CommonHelper.getApiUrl(route), this.dataCollector).then(
      (_response) => {
         this._mdDialog.hide(true);
         this._languageService.showSaveSuccess();
      },
      () => {
        this._languageService.whoops();
      },
    );
  }

  private authorise() {
    const dataProviderId = this.workAgentCollectors?.find((wac) => wac.id == this.dataCollector?.workAgentCollectorId).dataProviderId;
    const url = `${HttpUtils.removeTrailingSlash(AppSettings.app.oAuthBaseUrl)}/${this.accountId}/${dataProviderId}/authorize`;
    window.open(url, '_blank');
  }

  public authoriseAndSave() {
    this.authorise();
    this.save();
  }

  public closeDialog() {
    this._mdDialog.hide();
  }

  public checkProfileName() {
    if (this.dataCollector.name) {
      if (
        this._dataCollectors.some((a) => a.id != this.dataCollector.id && a.name.toLowerCase() == this.dataCollector.name.toLowerCase())
      ) {
        this.isNameExist = true;
      } else {
        this.isNameExist = false;
      }
    } else {
      this.isNameExist = false;
    }
  }

  public updateScheduler(scheduler: { hours: number[], minute: number }) {
    this.scheduler = {
      ...this.scheduler,
      hours: scheduler.hours.map((h) => h.toString().padStart(2, '0')),
      minutes: scheduler.minute.toString().padStart(2, '0'),
    };

    this.dataCollector.schedule = JSON.stringify(this.scheduler);
  }
}

angular.module('app.administration').controller('CollectorProfileDialogController', CollectorProfileDialogController);
