import * as angular from 'angular';
import { Asset } from 'src/app/_DBContext/Asset';
import { SysMessageLog } from 'src/app/_DBContext/SysMessageLog';
import { DateUtils } from '@indicina/swan-shared/utils/DateUtils';
import { SWANConstants } from '@common/SWANConstants';
import { IDropListItem } from '@common/models/interfaces';
import { DataEntityService } from '@services/data-entity.service';
import { PermissionService } from '@services/permission.service';
import { BaseController } from 'src/app/base.controller';

export class DataAgentController extends BaseController {
  public dataDirty: boolean = true;
  public arrLogsData: any;
  public arrLogsDataCSV: any;
  public arrLogsDataCSVhdr: any;

  public browserTimezoneLabel: string;
  public minDate = SWANConstants.MinDate;
  public logfromDate: Date;
  public logtoDate: Date;
  public logLevelId: number;
  public logLevels: IDropListItem[];

  public exportCSVdisabled: boolean = true;

  public currentPage: number = 1;
  public maxPage: number = 0;
  public maxDisplayRecord: number = 20;
  public totalInlineRecords: number = 0;

  constructor(
    $scope: angular.IScope,
    DataEntityService: DataEntityService,
    PermissionService: PermissionService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this.entityManager = DataEntityService.manager;
    this.browserTimezoneLabel = DateUtils.getBrowserTimezoneLabel();
    this.logLevels = SWANConstants.LogLevels;

    this.initialise();
  }

  private initialise() {
    this.logfromDate = new Date();
    this.logfromDate.setDate(this.logfromDate.getDate() - 2);
    this.logtoDate = new Date();
    this.logLevelId = 3;
    this.scope.$watchGroup(['vm.currentPage'], () => {
      this.refreshLogs();
    });
  }

  public onLogParamsChange() {
    this.currentPage = 1;
    this.refreshLogs();
  }

  private sysMessageLogsCallback(data) {
    this.arrLogsData = data.results;
    this.totalInlineRecords = data.inlineCount;
    this.maxPage = Math.ceil(this.totalInlineRecords / this.maxDisplayRecord);

    const logSums = [];
    //get run time csv extracts
    for (let k = 0; k < data.results.length; k++) {
      const log = data.results[k] as SysMessageLog;

      logSums.push({
        date: log.DateTime.toString(),
        level: log.Level.toString(),
        id: log.Id.toString(),
        comment: log.Comment.toString(),
      });
    }
    this.arrLogsDataCSV = logSums;
  }

  private refreshLogs() {
    this.exportCSVdisabled = true;
    this.arrLogsDataCSVhdr = ['Date', 'Level', 'AssetId', 'Comment', 'Extra'];

    const AssetClass: number = 5;
    let assetId: number = 0;
    //? this will ignore any collectors other than beau's (by design?) ?
    const collectorPred = breeze.Predicate.create('AssetClassId', '==', AssetClass).and('Name', '==', 'CollectorAgent');
    const qCollectors = breeze.EntityQuery.from('AccountAssets').withParameters({ accountId: this.accountId }).where(collectorPred);

    qCollectors.entityManager = this.entityManager;
    qCollectors.execute().then((res) => {
      assetId = (res.results[0] as Asset).AssetId;
      const logsPred = breeze.Predicate.create('DateTime', '>=', this.logfromDate)
        .and('DateTime', '<=', this.logtoDate)
        .and('Level', '<=', this.logLevelId);
      const queryLogs = breeze.EntityQuery.from('SysMessageLogAsset')
        .withParameters({ AssetId: assetId })
        .where(logsPred)
        .orderByDesc('DateTime')
        .skip((this.currentPage - 1) * this.maxDisplayRecord)
        .take(this.maxDisplayRecord)
        .inlineCount()
        .using(this.entityManager)
        .execute()
        .then(this.sysMessageLogsCallback.bind(this))
        .finally(() => {
          this.exportCSVdisabled = false;
        });
    });
  }
}

angular.module('app.administration').controller('DataAgentController', DataAgentController);
