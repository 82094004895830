import * as angular from 'angular';
import { SupportedLanguage } from '@indicina/swan-shared/interfaces/config/Settings';
import { SWANConstants } from '@common/SWANConstants';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { UsersService } from '@services/administration/users.service';
import { BaseController } from 'src/app/base.controller';
import { AppSettings } from '@indicina/swan-shared/AppSettings';

export class NewAccountUserDialogController extends BaseController {
  private contact: fuse.AccountUser;
  public email: string;
  public resultNewUser: boolean;
  public resultExistingSwanUser: boolean;
  public resultExistingAccountUser: boolean;
  public isSearching: boolean;
  public isUserDisabled: boolean;

  public premixedUoms = [] as string[];
  public supportedLanguages: SupportedLanguage[];
  public premixedUomName = null as string;
  public languageCode = null as string;

  private _http: angular.IHttpService;
  private _mdDialog: angular.material.IDialogService;
  private _languageService: LanguageService;
  private _usersService: UsersService;

  constructor(
    $http: angular.IHttpService,
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    UsersService: UsersService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._http = $http;
    this._mdDialog = $mdDialog;
    this._languageService = LanguageService;
    this._usersService = UsersService;

    this.supportedLanguages = AppSettings.i18nexus.supportedLanguages;
    this.resultNewUser = false;
    this.resultExistingSwanUser = false;
    this.resultExistingAccountUser = false;
    this.contact = null;
    this.email = '';
    this.isSearching = false;
    this.isUserDisabled = false;
  }

  $onInit() {
    this._http.get(CommonHelper.getApiUrl('profile/getPremixedUoms')).then((res) => {
      this.premixedUoms = res.data as string[];
    });
  }

  public resetResults() {
    // reset results
    this.resultNewUser = false;
    this.resultExistingAccountUser = false;
    this.resultExistingSwanUser = false;
  }

  public searchUser() {
    this.resetResults();
    this.contact = null;
    this.isUserDisabled = false;
    this.isSearching = true;
    this._usersService
      .findApplicationUser(this.email)
      .then((result) => {
        if (!result) {
          this.resultNewUser = true;
          // new user
          this.contact = {
            Id: '',
            FirstName: '',
            LastName: '',
            UserName: this.email.toLowerCase(),
            Email: this.email.toLowerCase(),
            PhoneNumber: '',
            ContactMobileNumber: '',
            AuthAccountId: this.accountId,
            Role: SWANConstants.RoleCodes.VIEWER, // default to viewer
          } as fuse.AccountUser;
        } else {
          let found: boolean = false;
          for (let i = 0; i < result.UserAccountAccess.length; i++) {
            if (result.UserAccountAccess[i].AuthAccountId == this.accountId) {
              found = true;
              break;
            }
          }
          if (result.UserAccountStatus == 'Disabled') {
            this.isUserDisabled = true;
          }
          this.resultExistingAccountUser = found;
          this.resultExistingSwanUser = !found;
          // transform server result from ApplicationUser type to simple contact object
          this.contact = {
            Id: result.Id,
            FirstName: result.FirstName,
            LastName: result.LastName,
            UserName: result.UserName,
            Email: result.Email,
            PhoneNumber: result.PhoneNumber,
            ContactMobileNumber: result.ContactMobileNumber,
            AuthAccountId: this.accountId,
            Role: SWANConstants.RoleCodes.VIEWER, // default to viewer
            languageCode: result.languageCode,
          } as fuse.AccountUser;
        }
      })
      .catch((error) => {
        let serverMessage = 'Server error occurred!';
        if (error.data.ExceptionMessage) {
          serverMessage = serverMessage + ' ' + error.data.ExceptionMessage;
        }
        this._languageService.warning(serverMessage);
      })
      .finally(() => {
        this.isSearching = false;
      });
  }

  public createNewUser() {
    this.closeDialog('create');
  }

  public addExistingUser() {
    this.closeDialog('add');
  }

  public closeDialog(action = '') {
    this._mdDialog.hide({
      action: action,
      contact: this.contact,
      premixedUomName: this.premixedUomName,
      languageCode: this.languageCode,
    });
  }
}

angular.module('app.administration').controller('NewAccountUserDialogController', NewAccountUserDialogController);
