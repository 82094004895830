import * as angular from 'angular';
import { ApplicationPrivileges } from '@common/ApplicationPrivileges';
import { AccountsService } from '@services/administration/accounts.service';
import { RolePrivilegeDialogController } from './role_privilege_dialog.controller';

export class RolePrivilegeController {
  public ApplicationPrivileges = ApplicationPrivileges;
  public privileges: fuse.privilegeDto[];
  public rolesPrivileges: fuse.rolePrivilegeDto[];
  public rolePrivilegeLookup: Record<string, Record<string, boolean>> = {};

  private _mdDialog: angular.material.IDialogService;
  private _accountService: AccountsService;

  constructor($mdDialog: angular.material.IDialogService, AccountsService: AccountsService) {
    this._mdDialog = $mdDialog;
    this._accountService = AccountsService;
  }

  $onInit() {
    this.getPrivileges();
  }

  public editRolePrivilege(item: fuse.rolePrivilegeDto): void {
    const rolePrivileges = {} as fuse.rolePrivilegeDto;

    rolePrivileges.role = item.role;
    rolePrivileges.privileges = [] as fuse.privilegeDto[];

    this.privileges.forEach((privilege) => {
      rolePrivileges.privileges.push({
        privilegeCode: privilege.privilegeCode,
        checked: item.privileges.some((a) => a.privilegeCode === privilege.privilegeCode),
      } as fuse.privilegeDto);
    });

    this._mdDialog
      .show({
        controller: RolePrivilegeDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/administration/views/system/role_privilege/role_privilege_dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: true,
        locals: {
          rolePrivileges: rolePrivileges,
        },
      })
      .then(() => {
        this._accountService.getRolesPrivileges().then((results) => {
          this.rolesPrivileges = results;
        });
      });
  }

  private createRolePrivilegeLookup(): void {
    this.rolesPrivileges.forEach((rolePrivilege) => {
      const { role, privileges: rolePrivileges } = rolePrivilege;

      this.rolePrivilegeLookup[role] = this.privileges.reduce((acc, curr) => {
        acc[curr.privilegeCode] = rolePrivileges.some((rp) => rp.privilegeCode === curr.privilegeCode);

        return acc;
      }, {});
    });
  }

  private getPrivileges(): void {
    this._accountService.getPrivileges().then((results) => {
      this.privileges = results;

      this._accountService.getRolesPrivileges().then((results) => {
        this.rolesPrivileges = results;

        this.createRolePrivilegeLookup();
      });
    });
  }
}

angular.module('app.administration').controller('RolePrivilegeController', RolePrivilegeController);
