// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#admin-account .header {
  height: 56px;
}
#admin-account .content-container {
  padding: 24px;
  margin: 0;
}
#admin-account .detail-module {
  padding-bottom: 16px;
}
#admin-account .detail-module table thead tr {
  background-color: dimgrey;
  color: white;
}
#admin-account .detail-module table thead tr th {
  text-align: center;
}
#admin-account .detail-module table tbody tr td {
  text-align: center;
}
#admin-account #account-module-table {
  overflow: auto;
}
#admin-account #account-module-table table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}
#admin-account #account-module-table table thead tr th {
  background-color: #666;
  color: #fff;
  text-align: center;
  padding-top: 2px;
  padding-bottom: 2px;
}
#admin-account #account-module-table table tbody tr {
  border-bottom: 1px solid #ccc;
}
#admin-account #account-module-table table tbody tr td {
  text-align: center;
  padding-top: 2px;
  padding-bottom: 2px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/administration/views/account-details/account-details.scss"],"names":[],"mappings":"AACE;EACE,YAAA;AAAJ;AAGE;EACE,aAAA;EACA,SAAA;AADJ;AAIE;EACE,oBAAA;AAFJ;AAMQ;EACE,yBAAA;EACA,YAAA;AAJV;AAMU;EACE,kBAAA;AAJZ;AAWU;EACE,kBAAA;AATZ;AAgBE;EACE,cAAA;AAdJ;AAgBI;EACE,WAAA;EACA,iBAAA;EACA,yBAAA;AAdN;AAkBU;EACE,sBAAA;EACA,WAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAhBZ;AAsBQ;EACE,6BAAA;AApBV;AAsBU;EACE,kBAAA;EACA,gBAAA;EACA,mBAAA;AApBZ","sourcesContent":["#admin-account {\r\n  .header {\r\n    height: 56px;\r\n  }\r\n\r\n  .content-container {\r\n    padding: 24px;\r\n    margin: 0;\r\n  }\r\n\r\n  .detail-module {\r\n    padding-bottom: 16px;\r\n\r\n    table {\r\n      thead {\r\n        tr {\r\n          background-color: dimgrey;\r\n          color: white;\r\n\r\n          th {\r\n            text-align: center;\r\n          }\r\n        }\r\n      }\r\n\r\n      tbody {\r\n        tr {\r\n          td {\r\n            text-align: center;\r\n          }\r\n        }\r\n      }\r\n    }\r\n  }\r\n\r\n  #account-module-table {\r\n    overflow: auto;\r\n\r\n    table {\r\n      width: 100%;\r\n      border-spacing: 0;\r\n      border-collapse: collapse;\r\n\r\n      thead {\r\n        tr {\r\n          th {\r\n            background-color: #666;\r\n            color: #fff;\r\n            text-align: center;\r\n            padding-top: 2px;\r\n            padding-bottom: 2px;\r\n          }\r\n        }\r\n      }\r\n\r\n      tbody {\r\n        tr {\r\n          border-bottom: 1px solid #ccc;\r\n\r\n          td {\r\n            text-align: center;\r\n            padding-top: 2px;\r\n            padding-bottom: 2px;\r\n          }\r\n        }\r\n      }\r\n    }\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
