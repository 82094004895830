// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/images/backgrounds/irrigatedAg2Hort2v3 1920x1078.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#login {
  height: 100vh;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
}
#login #login-form-wrapper {
  padding: 32px;
  display: flex;
  width: 100%;
  justify-content: center;
}
#login #login-form-wrapper #login-form {
  width: 385px;
  padding: 32px;
  padding-top: 0px;
  background: #FFFFFF;
  text-align: center;
  opacity: 1;
  border-radius: 10px;
}
#login #login-form-wrapper #login-form .title {
  font-size: 17px;
  margin: 24px 0 32px 0;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/auth/login/login.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,6DAAA;EACA,sBAAA;EACA,aAAA;EACA,mBAAA;AACF;AACE;EACE,aAAA;EACA,aAAA;EACA,WAAA;EACA,uBAAA;AACJ;AACI;EACE,YAAA;EACA,aAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,UAAA;EACA,mBAAA;AACN;AACM;EACE,eAAA;EACA,qBAAA;AACR","sourcesContent":["#login {\r\n  height: 100vh;\r\n  background: url('assets/images/backgrounds/irrigatedAg2Hort2v3 1920x1078.jpg') no-repeat;\r\n  background-size: cover;\r\n  display: flex;\r\n  align-items: center;\r\n\r\n  #login-form-wrapper {\r\n    padding: 32px;\r\n    display: flex;\r\n    width: 100%;\r\n    justify-content: center;\r\n\r\n    #login-form {\r\n      width: 385px;\r\n      padding: 32px;\r\n      padding-top: 0px;\r\n      background: #FFFFFF;\r\n      text-align: center;\r\n      opacity: 1;\r\n      border-radius: 10px;\r\n\r\n      .title {\r\n        font-size: 17px;\r\n        margin: 24px 0 32px 0;\r\n      }\r\n    }\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
