import * as angular from 'angular';
import { AppSettings } from '@indicina/swan-shared/AppSettings';
import { SupportedLanguage } from '@indicina/swan-shared/interfaces/config/Settings';
import { SWANConstants } from '@common/SWANConstants';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { TableControl } from '@common/helpers/TableControl';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { UsersService } from '@services/administration/users.service';
import { AuthZeroService } from '@services/auth-zero.service';
import { UserAccountDialogController } from './user-account-dialog.controller';
import { BaseController } from 'src/app/base.controller';

export class UserDialogController extends BaseController {
  private _http: angular.IHttpService;
  private _mdDialog: angular.material.IDialogService;
  private _authZeroService: AuthZeroService;
  private _languageService: LanguageService;
  private _usersService: UsersService;

  public tableControl = new TableControl({ searchField: 'CompanyName', pageSize: 5 });
  public premixedUomName = null as string;
  public premixedUoms = [] as string[];

  public supportedLanguages: SupportedLanguage[];
  public title: string;
  public contact: fuse.ApplicationUser;
  public newUser = false;
  public phones: { phone1: string; phone2: string };
  public isSaving: boolean = false;
  public userStatus: string[];
  public accountsChanged = false;
  public enableAddUserButton = false;

  constructor(
    $http: angular.IHttpService,
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    AuthZeroService: AuthZeroService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    UsersService: UsersService,
    contact: fuse.ApplicationUser,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._http = $http;
    this._authZeroService = AuthZeroService;
    this._languageService = LanguageService;
    this._usersService = UsersService;

    this.supportedLanguages = AppSettings.i18nexus.supportedLanguages;
    this.title = this._languageService.instant('ADM.USER.TITLE_EDIT');
    this.contact = contact;
    this.newUser = false;
    this.userStatus = SWANConstants.UserStatuses;
    this.accountsChanged = false;

    if (!this.contact) {
      this.contact = {
        UserAccountStatus: 'Active',
        UserAccountAccess: [] as fuse.ApplicationAccountAccess[],
      } as fuse.ApplicationUser;

      this.title = this._languageService.instant('ADM.USER.TITLE_ADD');
      this.newUser = true;
    } else {
      // Trim the phone numbers, as previous app versions were adding empty space as the value for a non specified phone number.
      this.contact.PhoneNumber = this.contact.PhoneNumber?.trim();
      this.contact.ContactMobileNumber = this.contact.ContactMobileNumber?.trim();
    }

    this.scope.$watchGroup(['vm.contact.PhoneNumber', 'vm.contact.ContactMobileNumber'], () => {
      this.phones = {
        phone1: this.contact.PhoneNumber,
        phone2: this.contact.ContactMobileNumber,
      };

      this._validForm();
    });

    this.scope.$watchGroup(['vm.contact.FirstName', 'vm.contact.Email'], () => {
      this._validForm();
    });

    this.scope.$watchCollection('vm.contact.UserAccountAccess', () => {
      this._validForm();
    });
    this.scope.$watch('vm.contact.UserAccountStatus', () => {
      this._validForm();
    });
  }

  $onInit() {
    this._http.get(CommonHelper.getApiUrl('profile/getPremixedUoms')).then((res) => {
      this.premixedUoms = res.data as string[];
    });
  }

  public showAddUserConfirmation() {
    const confirm = this._mdDialog
      .confirm()
      .title(this._languageService.instant('ADM.USER.TITLE_ADD'))
      .htmlContent(this._languageService.instant('ADM.USER.CONFIRM_ADD') + '<br/> <br/>' + this.contact.Email + '<br/>')
      .multiple(true)
      .ok(this._languageService.instant('COMMON.YES'))
      .cancel(this._languageService.instant('COMMON.NO'));
    this._mdDialog.show(confirm).then(() => {
      this.addNewUser();
    });
  }

  public notifyNewAccountAccess() {
    const confirm = this._mdDialog
      .confirm()
      .title(this._languageService.instant('ADM.ACCOUNT_DETAILS.ACCESS_CHANGED'))
      .htmlContent(this._languageService.instant('ADM.ACCOUNT_DETAILS.ACCESS_CHANGED_MSG'))
      .multiple(true)
      .ok(this._languageService.instant('COMMON.OK'));
    this._mdDialog.show(confirm);
  }

  private addNewUser() {
    if (this.contact.FirstName != '' && this.contact.Email != '') {
      this.isSaving = true;
      this.contact.premixedUomName = this.premixedUomName;
      this._usersService
        .createApplicationUser(this.contact)
        .then((res) => {
          this._languageService.showSaveSuccess();
          this._mdDialog.hide(true);
        })
        .catch((error) => {
          if (error.data) {
            this._languageService.error(error.data);
          } else {
            this._languageService.whoops();
          }
        })
        .finally(() => {
          this.isSaving = false;
        });
    } else {
      this._languageService.warning('ADM.USER.MISSING_ITEMS');
    }
  }

  public saveUser() {
    this.isSaving = true;
    this._usersService
      .updateApplicationUser(this.contact)
      .then((results) => {
        this.permissionService.reloadAvailableAccounts();
        this._languageService.success('COMMON.CHANGES_SAVED');
        this._mdDialog.hide(true).then(() => {
          if (this.accountsChanged) {
            this.notifyNewAccountAccess();
          }
        });
      })
      .catch((error) => {
        if (error.data) {
          this._languageService.error(error.data);
        }
      })
      .finally(() => {
        this.isSaving = false;
      });
  }

  public openAddAccountDialog(ev: MouseEvent) {
    this._mdDialog
      .show({
        controller: UserAccountDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/administration/views/system/users/user-account-dialog.html',
        parent: angular.element(document.body),
        multiple: true,
        targetEvent: ev,
        clickOutsideToClose: true,
        locals: {
          contact: this.contact,
        },
      })
      .then((returnedAccount) => {
        if (returnedAccount) {
          this.contact.UserAccountAccess.push(returnedAccount);
          this.scope['customerForm'].$setDirty();
          if (this.isCurrentUser()) {
            this.accountsChanged = true;
          }
        }
      });
  }

  public deleteUserAccountAccess(account: fuse.ApplicationAccountAccess) {
    const confirm = this._mdDialog
      .confirm()
      .title(this._languageService.instant('ADM.USER.CONFIRM_REMOVE'))
      .htmlContent(
        '<b>' +
          this.contact.Email +
          '</b> ' +
          this._languageService.instant('ADM.USER.WILL_BE_REMOVED') +
          ' <b>' +
          account.CompanyName +
          '</b>.',
      )
      .multiple(true)
      .ok('REMOVE')
      .cancel('CANCEL');
    this._mdDialog.show(confirm).then(() => {
      const idx: number = this.contact.UserAccountAccess.indexOf(account);
      this.contact.UserAccountAccess.splice(idx, 1);
      this.scope['customerForm'].$setDirty();
    });
  }

  public deleteUser() {
    const confirm = this._mdDialog
      .confirm()
      .title(this._languageService.instant('ADM.USER.CONFIRM_DELETE'))
      .htmlContent('<b>' + this.contact.Email + '</b> ' + this._languageService.instant('ADM.USER.WILL_BE_DELETED'))
      .multiple(true)
      .ok('DELETE USER')
      .cancel('CANCEL');
    this._mdDialog.show(confirm).then(() => {
      this.isSaving = true;
      this._usersService
        .deleteUser(this.contact.Id)
        .then((res) => {
          this._languageService.success('ADM.USER.DELETED');
          this._mdDialog.hide(true);
        })
        .catch((error) => {
          let errorMessage = this._languageService.instant('ADM.USER.DELETE_FAILED');

          if (error.data.Message) {
            errorMessage = errorMessage.concat(` ${this._languageService.instant(error.data.Message)}`);
          }

          this._languageService.errorMessage(errorMessage);
        })
        .finally(() => {
          this.isSaving = false;
        });
    });
  }

  public closeDialog() {
    if (this.scope['customerForm'].$dirty) {
      const confirm = this._languageService.closeDialog();
      this._mdDialog.show(confirm).then(() => {
        this._mdDialog.hide();
      });
    } else {
      this._mdDialog.hide();
    }
  }

  public onSearchChanged(texboxSearchID: string): void {
    const objCustForm = this.scope['customerForm'] as angular.IFormController;
    let otherFieldHasChanged = false;
    Object.keys(objCustForm).forEach((objectName, key) => {
      if (!otherFieldHasChanged) {
        const htmlObj = this.scope['customerForm'][objectName] as angular.INgModelController;
        if (htmlObj != null && angular.isDefined(htmlObj.$dirty) && objectName != texboxSearchID) {
          otherFieldHasChanged = htmlObj.$dirty;
        }
      }
    });
    if (!otherFieldHasChanged) {
      objCustForm.$setPristine();
    }
  }

  public getRoles(accountId: number) {
    if (accountId === 1) {
      return SWANConstants.RoleCodes;
    }
    return SWANConstants.AccountRoles;
  }

  public isCurrentUserSysAdmin(account) {
    const loginUserEmail = this._authZeroService.getEmail();
    if (
      account.Role === SWANConstants.RoleCodes.SYSADMIN &&
      this.contact.Email.toLowerCase().trim() === loginUserEmail
    ) {
      return true;
    }
  }

  public isCurrentUser() {
    const loginUserEmail = this._authZeroService.getEmail();
    if (this.contact.Email.toLowerCase().trim() === loginUserEmail) {
      return true;
    }
  }

  public emailAccountAccess(): void {
    this._usersService.emailAccountAccess(this.contact);
  }

  private _validForm() {
    if (
      this.contact.FirstName
      && this.contact.Email
      && (this.contact.UserAccountStatus == 'Disabled' || (this.contact.UserAccountStatus == 'Active' && this.contact.UserAccountAccess?.length))
      && (this.contact.PhoneNumber || this.contact.ContactMobileNumber)
    ) {
      this.enableAddUserButton = true;
    } else {
      this.enableAddUserButton = false;
    }
  }
}

angular.module('app.administration').controller('UserDialogController', UserDialogController);
