import * as angular from 'angular';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { AccountsService } from '@services/administration/accounts.service';
import { BaseController } from 'src/app/base.controller';

export class AccountAddExternalApiDialogController extends BaseController {
  private _mdDialog: angular.material.IDialogService;
  private _accountsService: AccountsService;
  private _languageService: LanguageService;

  public apiList: string[];
  public apiPartnerList: string[];
  public apiPermissionTypeList: string[];
  public apiName: string;
  public apiPartner: string;
  public apiPermission: string;
  public apiReference: string;
  private accountDetail: fuse.accountDetail;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    AccountsService: AccountsService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    account: fuse.accountDetail,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._accountsService = AccountsService;
    this._languageService = LanguageService;

    this.accountDetail = account;
    this.apiList = [];
    this.apiPartnerList = [];
    this.apiPermissionTypeList = [];
    this.fetchData();
  }

  private fetchData() {
    this._accountsService.getApis().then((results) => {
      this.apiList = results;
    });
    this._accountsService.getApiPartners().then((results) => {
      this.apiPartnerList = results;
    });
    this._accountsService.getPermissionTypes().then((results) => {
      this.apiPermissionTypeList = results;
    });
  }

  public addExternalApi() {
    const externalApiDto = {
      accountId: this.accountDetail.authAccountId,
      apiGrantTo: this.apiPartner,
      apiName: this.apiName,
      apiPermission: this.apiPermission,
      apiReference: this.apiReference,
    } as fuse.externalApiDto;
    this._accountsService.addExternalApi(externalApiDto).then(
      (results) => {
        if (results.succeeded == true) {
          this._languageService.success(results.message);
          this._mdDialog.hide({ dataRefreshRequired: true });
        } else {
          this._languageService.error(results.message);
        }
      },
      () => {
        this._languageService.whoops();
      },
    );
  }

  public closeDialog() {
    if (this.scope['addApiForm'].$dirty) {
      const confirm = this._languageService.closeDialog();
      this._mdDialog.show(confirm).then(
        () => {
          this._mdDialog.hide({ dataRefreshRequired: false });
        },
        () => {
          // user canceled the close - don't close the form
        },
      );
    } else {
      this._mdDialog.hide();
    }
  }

  public hasSubscription(subscriptionName: string): boolean {
    if (subscriptionName != undefined) {
      return this.accountDetail.subscriptions.some((a) => a.name.toLocaleLowerCase().indexOf(subscriptionName.toLowerCase()) >= 0);
    }
  }
}

angular.module('app.administration').controller('AccountAddExternalApiDialogController', AccountAddExternalApiDialogController);
