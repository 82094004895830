import * as angular from 'angular';
import { EntityList } from '@common/EntityList';
import { ApplicationPrivileges } from '@common/ApplicationPrivileges';
import { AccountsService } from '@services/administration/accounts.service';
import { AuthZeroService } from '@services/auth-zero.service';
import { LanguageService } from '@services/language.service';
import { NotifyEvents, NotifyingService } from '@services/notifying.service';
import { PermissionService } from '@services/permission.service';
import { BaseController } from 'src/app/base.controller';
import { AccountUserDialogController } from './account-user-dialog.controller';
import { NewAccountUserDialogController } from './new-account-user-dialog.controller';

export class AccountUsersController extends BaseController {
  public filteredContacts = [] as fuse.AccountUser[];

  private _accountService: AccountsService;
  private _languageService: LanguageService;
  private _notifyingService: NotifyingService;

  private entityList: EntityList<any>;
  private currentUserEmail: string;

  constructor(
    $scope: angular.IScope,
    AccountsService: AccountsService,
    AuthZeroService: AuthZeroService,
    LanguageService: LanguageService,
    NotifyingService: NotifyingService,
    PermissionService: PermissionService,
  ) {
    super(
      $scope,
      PermissionService,
    );
    this.setEditPermission(ApplicationPrivileges.AdminUsersFull);

    this._accountService = AccountsService;
    this._languageService = LanguageService;
    this._notifyingService = NotifyingService;

    this.currentUserEmail = AuthZeroService.getEmail();

    this._notifyingService.subscribe(NotifyEvents.Admin.UserUpdate, $scope, (event, data) => {
      this._fetchData();
    });
  }

  $onInit() {
    this._fetchData();
  }

  private _fetchData() {
    this._accountService.getAccountUsers().then((data) => {
      data.forEach((user) => {
        if (user.LoginToAccountTime) {
          user.LoginToAccountTime = new Date(user.LoginToAccountTime + 'Z');
        }

        if (user.LoginToSWANTime) {
          user.LoginToSWANTime = new Date(user.LoginToSWANTime + 'Z');
        }

        user['FullName'] = `${user.FirstName} ${user.LastName}`;
      });

      this.entityList = new EntityList(data as any);

      this.entityList.setTranslation(this._languageService, 'Role', 'ROLE');
    });
  }

  public removeUser(item: fuse.AccountUser) {
    // Ensure the user is not attempting to delete themselves
    if (item.Email == this.currentUserEmail) {
      const alert = this._languageService
        .alert()
        .title('ADM.USER.CANNOT_REMOVE_SELF')
        .htmlContent('ADM.USER.CANNOT_REMOVE_SELF')
        .ok('COMMON.OK');
      this._languageService.show(alert);
    } else {
      const confirm = this._languageService
        .confirm()
        .title('ADM.USER.REMOVE_USER_FROM_ACCOUNT')
        .htmlContent('ADM.USER.REMOVE_MSG', { user: item['FullName']})
        .ok('ADM.USER.REMOVE_USER_CAPS')
        .cancel('COMMON.CANCEL');
      this._languageService.show(confirm).then(() => {
        this._accountService.removeUserFromAccount(item)
          .then((res) => {
            this._languageService.success('ADM.USER.REMOVED_USER_ACCOUNT_ACCESS');
            this._languageService.hide().then(() => {
              this._notifyingService.notify(NotifyEvents.Admin.UserUpdate, null);
            });
          })
          .catch(() => {
            this._languageService.warning('ADM.USER.ACCOUNT_ACCESS_NOT_REMOVED');
          });
      });
    }
  }

  public openAddUserDialog() {
    this._languageService
      .show({
        controller: NewAccountUserDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/administration/views/account-users/new-account-user-dialog.html',
        parent: angular.element(document.body),
      })
      .then((returnData) => {
        if (returnData) {
          if (returnData.action == 'create') {
            this.openUserDialog(returnData.contact, returnData.action, returnData.premixedUomName, returnData.languageCode);
          } else if (returnData.action == 'add') {
            this.openUserDialog(returnData.contact, returnData.action, null, null);
          }
        }
      });
  }

  public openUserDialog(contact: fuse.AccountUser, action = 'add', premixedUomName: string, languageCode: string) {
    this._languageService
      .show({
        controller: AccountUserDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/administration/views/account-users/account-user-dialog.html',
        parent: angular.element(document.body),
        locals: {
          item: contact,
          action: action,
          premixedUomName: premixedUomName,
          languageCode: languageCode,
        },
      })
      .then(() => {
        this.entityList.updateTranslations(this._languageService);
      });
  }
}

angular.module('app.administration').controller('AccountUsersController', AccountUsersController);
