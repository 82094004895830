// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#administration > .header {
  height: 100px;
  min-height: 60px;
  max-height: 80px;
}
#administration .content-container {
  padding: 24px;
  margin: 0;
}
#administration .form-data-wrapper {
  margin-left: 16px;
}
#administration .form-data-wrapper .json-data-wrapper {
  overflow: hidden;
}
#administration .form-data-wrapper .json-data-wrapper pre {
  font-size: 16px;
}
#administration .inputPaddingBottom {
  padding-bottom: 24px;
}
#administration .inputPaddingTopBottom {
  padding-bottom: 24px;
  padding-top: 24px;
}
#administration .detail-module {
  padding-bottom: 16px;
}
#administration .detail-module table thead tr {
  background-color: dimgrey;
  color: white;
}
#administration .detail-module table thead tr th {
  text-align: center;
}
#administration .detail-module table tbody tr td {
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/administration/administration.scss"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,gBAAA;EACA,gBAAA;AAAR;AAGI;EACI,aAAA;EACA,SAAA;AADR;AAII;EACI,iBAAA;AAFR;AAIQ;EACI,gBAAA;AAFZ;AAIY;EACI,eAAA;AAFhB;AAOI;EACI,oBAAA;AALR;AAQI;EACI,oBAAA;EACA,iBAAA;AANR;AAQI;EACI,oBAAA;AANR;AASgB;EACI,yBAAA;EACA,YAAA;AAPpB;AAQoB;EACI,kBAAA;AANxB;AAYoB;EACI,kBAAA;AAVxB","sourcesContent":["#administration {\r\n    > .header {\r\n        height: 100px;\r\n        min-height: 60px;\r\n        max-height: 80px;\r\n    }\r\n\r\n    .content-container {\r\n        padding: 24px;\r\n        margin: 0;\r\n    }\r\n\r\n    .form-data-wrapper {\r\n        margin-left: 16px;\r\n\r\n        .json-data-wrapper {\r\n            overflow: hidden;\r\n\r\n            pre {\r\n                font-size: 16px;\r\n            }\r\n        }\r\n    }\r\n\r\n    .inputPaddingBottom {\r\n        padding-bottom: 24px;\r\n    }\r\n\r\n    .inputPaddingTopBottom {\r\n        padding-bottom: 24px;\r\n        padding-top: 24px;\r\n    }\r\n    .detail-module {\r\n        padding-bottom: 16px;\r\n        table {\r\n            thead {\r\n                tr {\r\n                    background-color: dimgrey;\r\n                    color: white;\r\n                    th {\r\n                        text-align: center;\r\n                    }\r\n                }\r\n            }\r\n            tbody {\r\n                tr {\r\n                    td {\r\n                        text-align: center;\r\n                    }\r\n                }\r\n            }\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
