// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#admin-users md-input-container {
  margin: 0 0 8px;
  padding: 0;
}
#admin-users md-input-container .md-errors-spacer {
  min-height: 0px !important;
}
#admin-users md-input-container .md-input {
  color: white;
  border-color: white;
  width: 80px;
}
#admin-users table {
  width: 100%;
  min-width: 600px;
}
#admin-users .user-info {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
#admin-users .user-role {
  width: 8%;
}
#admin-users .user-remove {
  width: 10%;
}
#admin-users .user-time {
  width: 15%;
}
#admin-users .user-clear {
  width: 8%;
  white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/administration/views/account-users/account-users.scss"],"names":[],"mappings":"AACI;EACI,eAAA;EACA,UAAA;AAAR;AACQ;EAEI,0BAAA;AAAZ;AAEQ;EACI,YAAA;EACA,mBAAA;EACA,WAAA;AAAZ;AAGI;EACI,WAAA;EACA,gBAAA;AADR;AAGI;EACI,gBAAA;EACA,mBAAA;EACA,uBAAA;AADR;AAGI;EACI,SAAA;AADR;AAGI;EACI,UAAA;AADR;AAGI;EACI,UAAA;AADR;AAGI;EACI,SAAA;EACA,mBAAA;AADR","sourcesContent":["#admin-users {\r\n    md-input-container {\r\n        margin: 0 0 8px;\r\n        padding: 0;\r\n        .md-errors-spacer {\r\n            //display: inline;\r\n            min-height: 0px !important;\r\n        }\r\n        .md-input {\r\n            color: white;\r\n            border-color: white;\r\n            width: 80px;\r\n        }\r\n    }\r\n    table {\r\n        width: 100%;\r\n        min-width: 600px;\r\n    }\r\n    .user-info {\r\n        overflow: hidden;\r\n        white-space: nowrap;\r\n        text-overflow: ellipsis;\r\n    }\r\n    .user-role{\r\n        width:8%;\r\n    }\r\n    .user-remove {\r\n        width:10%;\r\n    }\r\n    .user-time {\r\n        width:15%;\r\n    }\r\n    .user-clear {\r\n        width:8%;\r\n        white-space: nowrap;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
