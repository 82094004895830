import * as angular from 'angular';
import { LoginController } from './login/login.controller';
import { MainController } from '../../main.controller';

const authConfig = (
  $stateProvider: angular.ui.IStateProvider,
) => {
  $stateProvider.state('app.auth', {
    abstract: true,
    views: {
      'main@': {
        templateUrl: 'src/app/pages/auth/content-only.html',
        controller: MainController,
        controllerAs: 'vm',
      },
    },
  });
  $stateProvider.state('app.auth.login', {
    url: '/login',
    params: {
      route: '',
      announcement: '',
      isOrphanUser: false,
      isApiError: false,
      isAuthError: false,
      isUnacceptedTC: false,
      isWrongVersion: false,
    },
    views: {
      'content@app.auth': {
        templateUrl: 'src/app/pages/auth/login/login.html',
        controller: LoginController,
        controllerAs: 'vm',
      },
    },
  });
}

angular.module('app.auth').config(['$stateProvider', authConfig]);
