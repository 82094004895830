import * as angular from 'angular';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { AccountsService } from '@services/administration/accounts.service';
import { BaseController } from 'src/app/base.controller';

export class AccountViewAccessKeyDialogController extends BaseController {
  private _mdDialog: angular.material.IDialogService;
  private _accountsService: AccountsService;
  private _languageService: LanguageService;

  public apiUrl: string;
  public apiKeyChangable: boolean;
  public apiUnits: string[];
  public apiTimezones: string[];
  public scheduledSites = [] as fuse.ScheduledSite[];

  private apiId: number;
  private apiName: string;
  private apiAccountId: number;
  private apiAccessKey: string;
  public apiUnit: string;
  public apiTimezone: string;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    AccountsService: AccountsService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    scheduledSites: fuse.ScheduledSite[],
    externalApiInfo: fuse.externalApiDto,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._accountsService = AccountsService;
    this._languageService = LanguageService;

    this.apiId = externalApiInfo.id;
    this.apiName = externalApiInfo.apiName;
    this.apiAccountId = externalApiInfo.accountId;
    this.apiAccessKey = externalApiInfo.apiKey;
    this.apiKeyChangable = externalApiInfo.apiKeyChangable;
    this.apiUnits = externalApiInfo.apiUnits;
    this.apiTimezones = externalApiInfo.apiTimezones;
    this.scheduledSites = scheduledSites;
    this.apiUnit = this.apiUnits[0];
    this.apiTimezone = this.apiTimezones[0];
    this.refreshUrl();
  }

  public regenerateAccessKey() {
    const confirm = this._mdDialog
      .confirm()
      .title(this._languageService.instant('ADM.ACCOUNT_DETAILS.REGENERATE_KEY'))
      .htmlContent(
        `<div style="width:300px"><b>` +
          this._languageService.instant('COMMON.WARNING') +
          `</b> – ` +
          this._languageService.instant('ADM.ACCOUNT_DETAILS.REGENERATE_WARNING', { n: this.apiName }),
      )
      .multiple(true)
      .ariaLabel(this._languageService.instant('ADM.ACCOUNT_DETAILS.REGENERATE_KEY'))
      .ok(this._languageService.instant('COMMON.YES'))
      .cancel(this._languageService.instant('COMMON.NO'));
    this._mdDialog.show(confirm).then((returnData) => {
      if (returnData) {
        this._accountsService.regenerateAccessKey(this.apiId).then(
          (response) => {
            if (response.succeeded == true) {
              this._languageService.success('ADM.ACCOUNT_DETAILS.KEY_REGENERATED');
              this.apiAccessKey = response.message;
              this.refreshUrl();
            } else {
              this._languageService.error(response.message);
            }
          },
          () => {
            this._languageService.whoops();
          },
        );
      }
    });
  }

  private refreshUrl() {
    const checkedSites = this.scheduledSites.filter((a) => {
      return a.checked == true;
    });
    const checkedSiteIds = checkedSites.map((a) => a.siteId);
    const checkedSiteIdStr = checkedSiteIds.join(',');
    this.apiUrl = `https://data.swansystems.com.au/externalapi/${this.apiName}?unit=${this.apiUnit}&timezone=${this.apiTimezone}&account=${this.apiAccountId}&sites=${checkedSiteIdStr}&key=${this.apiAccessKey}`;
  }

  public closeDialog() {
    this._mdDialog.hide();
  }
}

angular.module('app.administration').controller('AccountViewAccessKeyDialogController', AccountViewAccessKeyDialogController);
