import * as angular from 'angular';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { BaseController } from 'src/app/base.controller';

export class AccountDefaultsDialogController extends BaseController {
  private _http: angular.IHttpService;
  private _mdDialog: angular.material.IDialogService;
  private _languageService: LanguageService;

  private accountDetail: fuse.accountDetail;
  public isSaving: boolean = false;
  public crops: Swan.Crop[];

  constructor(
    $http: angular.IHttpService,
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    crops: Swan.Crop[],
    account: fuse.accountDetail,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._http = $http;
    this._mdDialog = $mdDialog;
    this._languageService = LanguageService;

    this.crops = crops;
    this.accountDetail = angular.copy(account);
  }

  public save() {
    this.isSaving = true;
    const body = {
      fallowCropId: this.accountDetail.fallowCropId,
      kcViMaxCloudCover: this.accountDetail.kcViMaxCloudCover,
      kcViMaxReadingAgeDays: this.accountDetail.kcViMaxReadingAgeDays,
      kcViMaxPositiveVariancePct: this.accountDetail.kcViMaxPositiveVariancePct,
      kcViMaxNegativeVariancePct: this.accountDetail.kcViMaxNegativeVariancePct,
      kcViUseForPredictions: this.accountDetail.kcViUseForPredictions,
      healthIndexMaximumAge: this.accountDetail.healthIndexMaximumAge,
      healthLowerReliabilityPercentile: this.accountDetail.healthLowerReliabilityPercentile,
      healthUpperReliabilityPercentile: this.accountDetail.healthUpperReliabilityPercentile,
      forecastRainUsePct: this.accountDetail.forecastRainUsePct,
    } as fuse.accountDetailDto;
    this._http
      .post(CommonHelper.getApiUrl('administration/updateAccountDefaults'), JSON.stringify(body))
      .then(() => {
        this._languageService.showSaveSuccess();
        this._mdDialog.hide({ refreshRequired: true });
      })
      .catch((error) => {
        this._languageService.showSaveFailure(error.data.Message);
      })
      .finally(() => {
        this.isSaving = false;
      });
  }

  public close() {
    if (this.scope['accountDefaultsForm'].$dirty) {
      const confirm = this._languageService.closeDialog();
      this._mdDialog.show(confirm).then(() => {
        this._mdDialog.hide();
      });
    } else {
      this._mdDialog.hide();
    }
  }

  public hasSubscription(subscriptionName: string): boolean {
    if (this.account) {
      return this.accountDetail.subscriptions.some((a) => a.name == subscriptionName);
    } else {
      return false;
    }
  }
}

angular.module('app.administration').controller('AccountDefaultsDialogController', AccountDefaultsDialogController);
