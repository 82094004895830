import * as angular from 'angular';
import { AccountsService } from '@services/administration/accounts.service';
import { SubscriptionPrivilegeDialogController } from './subscription_privilege_dialog.controller';
import { PermissionService } from '@services/permission.service';
import { BaseController } from 'src/app/base.controller';

export class SubscriptionPrivilegeController extends BaseController {
  public subscriptionsPrivileges: fuse.subscriptionPrivilegeDto[];

  private _mdDialog: angular.material.IDialogService;
  private _accountService: AccountsService;

  private _privileges: fuse.privilegeDto[];

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    AccountsService: AccountsService,
    PermissionService: PermissionService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._accountService = AccountsService;
  }

  $onInit() {
    this.getPrivileges();
    this.getSubscriptionsPrivileges();
  }

  private getPrivileges() {
    this._accountService.getPrivileges().then((results) => {
      this._privileges = results;
    });
  }

  private getSubscriptionsPrivileges() {
    this._accountService.getSubscriptionsPrivileges().then((results) => {
      this.subscriptionsPrivileges = results;
    });
  }

  public editSubscriptionPrivilege(item: fuse.subscriptionPrivilegeDto) {
    const subscriptionPrivileges = {} as fuse.subscriptionPrivilegeDto;

    subscriptionPrivileges.subscription = item.subscription;
    subscriptionPrivileges.privileges = [] as fuse.privilegeDto[];

    this._privileges.forEach((privilege) => {
      if (item.privileges.some((a) => a.privilegeCode == privilege.privilegeCode)) {
        subscriptionPrivileges.privileges.push({
          checked: true,
          privilegeCode: privilege.privilegeCode,
        } as fuse.privilegeDto);
      } else {
        subscriptionPrivileges.privileges.push({
          checked: false,
          privilegeCode: privilege.privilegeCode,
        } as fuse.privilegeDto);
      }
    });

    this._mdDialog
      .show({
        controller: SubscriptionPrivilegeDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/administration/views/system/subscription_privilege/subscription_privilege_dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: true,
        locals: {
          subscriptionPrivileges: subscriptionPrivileges,
        },
      })
      .then(() => {
        this.getSubscriptionsPrivileges();
      });
  }
}

angular.module('app.administration').controller('SubscriptionPrivilegeController', SubscriptionPrivilegeController);
