import * as angular from 'angular';
import { PermissionService } from '@services/permission.service';
import { BaseController } from 'src/app/base.controller';

class ConfigureAccountAssetsComponent implements angular.IComponentOptions {
  bindings = {
    accountAssets: '<',
    assetChange: '&',
  };
  controller = ConfigureAccountAssetsController;
  controllerAs = 'vm';
  templateUrl = 'src/app/pages/administration/views/account-details/data-capture/configure-account-assets.html';
}

class ConfigureAccountAssetsController extends BaseController {
  private assetChange: Function;
  public selectedAssets = [] as fuse.equipmentProfileDto[];
  private unselectedAssets = [] as fuse.equipmentProfileDto[];
  public filteredUnselectedAssets = [] as fuse.equipmentProfileDto[];
  public nameFilter = '';
  public typeFilter = '';
  public providerFilter = '';
  public isAllFilteredUnselectedAssetsChecked = false;
  public isAllSelectedAssetsChecked = false;

  constructor(
    $scope: angular.IScope,
    PermissionService: PermissionService,
  ) {
    super(
      $scope,
      PermissionService,
    );
  }

  $onInit() {
    this.scope.$watchGroup(['vm.nameFilter', 'vm.typeFilter', 'vm.providerFilter'], () => {
      this.filterUnselectedAssets();
    });
  }

  $onChanges(changes) {
    if (changes.accountAssets?.currentValue?.length) {
      this.unselectedAssets = changes.accountAssets.currentValue;
      this.filterUnselectedAssets();
    }
  }

  public filterUnselectedAssets() {
    if (this.unselectedAssets && this.unselectedAssets.length >= 0) {
      this.filteredUnselectedAssets = this.unselectedAssets.filter(
        (a) => a.assetName.toLowerCase().indexOf(this.nameFilter.toLowerCase()) > -1,
      );
      this.filteredUnselectedAssets = this.filteredUnselectedAssets.filter(
        (a) => a.assetClassName.toLowerCase().indexOf(this.typeFilter.toLowerCase()) > -1,
      );
      this.filteredUnselectedAssets = this.filteredUnselectedAssets.filter(
        (a) => a.provider.toLowerCase().indexOf(this.providerFilter.toLowerCase()) > -1,
      );
    }
  }

  public clearFilter(): void {
    this.nameFilter = '';
    this.typeFilter = '';
    this.providerFilter = '';
  }

  public selectedAssetsChanged() {
    this.assetChange({ selectedAssets: this.selectedAssets });
  }

  public pushCheckedUnselectedAssets(): void {
    const movedAssets = this.filteredUnselectedAssets.filter((a) => a.isChecked);

    if (movedAssets.length) {
      this.unselectedAssets = this.unselectedAssets.filter((a) => movedAssets.some((b) => b.assetId == a.assetId) == false);
      this.filterUnselectedAssets();
      movedAssets.forEach((a) => (a.isChecked = false));
      this.selectedAssets.push(...movedAssets);
      this.itemChanged();
      this.selectedAssetsChanged();
    }
  }

  public popCheckedSelectedAssets(): void {
    const movedAssets = this.selectedAssets.filter((a) => a.isChecked);
    if (movedAssets.length) {
      this.selectedAssets = this.selectedAssets.filter((a) => a.isChecked == false);
      movedAssets.forEach((a) => (a.isChecked = false));
      this.unselectedAssets.push(...movedAssets);
      this.filterUnselectedAssets();
      this.itemChanged();
      this.selectedAssetsChanged();
    }
  }

  public selectAllUnselected(): void {
    if (this.isAllFilteredUnselectedAssetsChecked) {
      this.filteredUnselectedAssets.forEach((a) => (a.isChecked = true));
    } else {
      this.filteredUnselectedAssets.forEach((a) => (a.isChecked = false));
    }
    this.itemChanged();
  }

  public selectAllSelected(): void {
    if (this.isAllSelectedAssetsChecked) {
      this.selectedAssets.forEach((a) => (a.isChecked = true));
    } else {
      this.selectedAssets.forEach((a) => (a.isChecked = false));
    }
  }

  public itemChanged(): void {
    if (this.selectedAssets.length) {
      this.isAllSelectedAssetsChecked = this.selectedAssets.every((a) => a.isChecked);
    } else {
      this.isAllSelectedAssetsChecked = false;
    }
    if (this.filteredUnselectedAssets.length) {
      this.isAllFilteredUnselectedAssetsChecked = this.filteredUnselectedAssets.every((a) => a.isChecked);
    } else {
      this.isAllFilteredUnselectedAssetsChecked = false;
    }
  }
}

angular.module('app.administration').component('configureAccountAssets', new ConfigureAccountAssetsComponent());
