import * as angular from 'angular';
import { PermissionService } from '@services/permission.service';
import { SWANConstants } from '@common/SWANConstants';
import { IIdNameItem } from '@common/models/interfaces';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { BaseController } from 'src/app/base.controller';

class ConfigureKcviOptionComponent implements angular.IComponentOptions {
  bindings = {
    onChange: '&',
  };
  controller = ConfigureKcviOptionsController;
  controllerAs = 'vm';
  templateUrl = 'src/app/pages/administration/views/account-details/kcvi/configureKcvi-options.html';
}

class ConfigureKcviOptionsController extends BaseController {
  private _http: angular.IHttpService;

  private onChange: Function;
  public minDate = SWANConstants.MinDate;
  public isDataSourceChecked = false;
  public imageProviderId: number;
  public isCalculationChecked = false;
  public algorithmId: number;
  public algorithmFromDate: Date;
  public isReplaceSubsequentAlgorithms: boolean;
  public indexValue: string;
  public resultMultiplier = 1;
  public isKcviUsedInCropChecked = false;
  public kcviCropTimes = [] as fuse.kcviCropTimeDto[];
  public isDeleteOverlappedCropTime: boolean;

  public imageryProviders = [] as fuse.imageryProviderDto[];
  public algorithms = [] as fuse.algorithmDto[];
  public indexValues = [] as IIdNameItem[];

  constructor(
    $http: angular.IHttpService,
    $scope: angular.IScope,
    PermissionService: PermissionService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._http = $http;

    this.indexValues = SWANConstants.AlgorithmValueFunctions;
  }

  $onInit() {
    this._http.get(CommonHelper.getApiUrl('administration/getAccountKcviInfo')).then((result) => {
      const accountKcviInfo = result.data as fuse.accountKcviInfoDto;
      this.imageryProviders = accountKcviInfo.imageryProviders;
      this.algorithms = accountKcviInfo.algorithms;
    });
  }

  public addCropTime(): void {
    this.kcviCropTimes.push({ isChecked: true } as fuse.kcviCropTimeDto);
    this.kcviOptionsChanged();
  }

  public removeCropTime(item: fuse.kcviCropTimeDto): void {
    this.kcviCropTimes = this.kcviCropTimes.filter((a) => a != item);
    this.kcviOptionsChanged();
  }

  public kcviUsedInCropCheckChanged() {
    if (this.isKcviUsedInCropChecked && !this.kcviCropTimes.length) {
      this.kcviCropTimes.push({ isChecked: true } as fuse.kcviCropTimeDto);
    }
    this.kcviOptionsChanged();
  }

  public kcviOptionsChanged() {
    const options = {
      isDataSourceChecked: this.isDataSourceChecked,
      imageryProviderId: this.imageProviderId,
      isCalculationChecked: this.isCalculationChecked,
      algorithmId: this.algorithmId,
      algorithmFromDate: this.algorithmFromDate,
      isReplaceSubsequentAlgorithms: this.isReplaceSubsequentAlgorithms,
      indexValue: this.indexValue,
      resultMultiplier: this.resultMultiplier,
      isKcviUsedInCropChecked: this.isKcviUsedInCropChecked,
      isDeleteOverlappedCropTime: this.isDeleteOverlappedCropTime,
      kcviCropTimes: this.kcviCropTimes,
    } as fuse.accountKcviOptionsDto;
    this.onChange({ kcviOptions: options });
  }
}

angular.module('app.administration').component('configureKcviOptions', new ConfigureKcviOptionComponent());
