import * as angular from 'angular';
import * as moment from 'moment';
import { Asset } from 'src/app/_DBContext/Asset';
import { SiteAsset } from 'src/app/_DBContext/SiteAsset';
import { SWANConstants } from '@common/SWANConstants';
import { recalculationStartType } from '@common/enums';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { AccountsService } from '@services/administration/accounts.service';
import { DayNumberService } from '@services/day-number.service';
import { FetchDataService } from '@services/fetch-data.service';
import { BaseController } from 'src/app/base.controller';

export class AccountRecalculateSMBDialogController extends BaseController {
  private _mdDialog: angular.material.IDialogService;
  private _q: angular.IQService;
  private _accountsService: AccountsService;
  private _languageService: LanguageService;

  private accountDetail: fuse.accountDetail;
  private _fetchDataService: FetchDataService;
  private _dayNumberService: DayNumberService;

  private sites: fuse.SiteStartDateDto[] = [];
  public groups: Asset[] = [];
  private siteFilter: number[] = [];
  private selectedSites: number[] = [];
  private selectedGroup: Asset;
  private adjustment: number = 0;
  private startDate: Date;
  private startType: recalculationStartType = recalculationStartType.default;
  private weatherRollup: boolean;
  private clearSMBOverrides: boolean;
  private isRerunKcviCalculation = false;
  public hasKcviSubscription = false;
  public step: number = 0;
  public adjustmentMin = -100;
  public adjustmentMax = 100;

  private unselectedChosen: number[] = [];
  private selectedChosen: number[] = [];

  private selectAllUnselectedState: boolean = false;
  private selectAllSelectedState: boolean = false;

  private currentDate: Date;
  public minDate: Date;

  private isSuperUser: boolean = false;
  public isCalculating = false;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $q: angular.IQService,
    $scope: angular.IScope,
    AccountsService: AccountsService,
    DayNumberService: DayNumberService,
    FetchDataService: FetchDataService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    account: fuse.accountDetail,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._q = $q;
    this._accountsService = AccountsService;
    this._dayNumberService = DayNumberService;
    this._fetchDataService = FetchDataService;
    this._languageService = LanguageService;

    this.accountDetail = account;
    this.hasKcviSubscription = PermissionService.hasSubscription('Kc Vegetation Index');
    this.isSuperUser = PermissionService.isSuperUser;
  }

  $onInit() {
    this.currentDate = new Date();
    const irrigationStartDate = new Date(
      this.accountDetail.irrigationSeasonStartMonth > this.currentDate.getMonth() + 1
        ? this.currentDate.getFullYear() - 1
        : this.currentDate.getFullYear(),
      this.accountDetail.irrigationSeasonStartMonth - 1,
      1,
    );
    this.minDate = this.isSuperUser
      ? SWANConstants.MinDate
      : moment(this.currentDate).add(-6, 'months') < moment(irrigationStartDate)
        ? moment(this.currentDate).add(-6, 'months').toDate()
        : irrigationStartDate;

    this.fetchData();
  }

  private unselectedSiteList(): fuse.SiteStartDateDto[] {
    return this.sites.filter((s) => this.selectedSites.indexOf(s.siteId) < 0 && this.siteFilter.indexOf(s.siteId) >= 0);
  }

  private selectedSiteList(): fuse.SiteStartDateDto[] {
    return this.sites.filter((s) => this.selectedSites.indexOf(s.siteId) >= 0);
  }

  public pushSelectedSites(): void {
    this.selectedSites = this.selectedSites.concat(this.unselectedChosen);
    this.unselectedChosen = [];
    this.selectAllSelectedState = false;
    this.selectAllUnselectedState = false;
  }

  public popSelectedSites(): void {
    this.selectedSites = this.selectedSites.filter((s) => this.selectedChosen.indexOf(s) < 0);
    this.selectedChosen = [];
    this.selectAllSelectedState = false;
    this.selectAllUnselectedState = false;
  }

  public selectAllUnselected(): void {
    if (this.selectAllUnselectedState) {
      this.unselectedChosen = this.unselectedSiteList().map((s) => s.siteId);
    } else {
      this.unselectedChosen = [];
    }
  }

  public selectAllSelected(): void {
    if (this.selectAllSelectedState) {
      this.selectedChosen = this.selectedSiteList().map((s) => s.siteId);
    } else {
      this.selectedChosen = [];
    }
  }

  public SMBStartTypeChanged(): void {
    switch (this.startType) {
      case recalculationStartType.pWP:
      case recalculationStartType.zero:
        this.adjustmentMin = 0;
        this.adjustmentMax = 100;
        break;
      case recalculationStartType.sP:
        this.adjustmentMin = -100;
        this.adjustmentMax = 0;
        break;
      default:
        this.adjustmentMin = -100;
        this.adjustmentMax = 100;
        break;
    }
  }

  private fetchData() {
    const promises: angular.IPromise<any>[] = [];
    promises.push(
      this._accountsService.getActiveSitesStartDate(this.accountDetail.authAccountId).then(
        (data) => {
          this.sites = data as fuse.SiteStartDateDto[];
          this.sites.forEach((s) => {
            (s as any).startDate =
              s.startDayNumber != null
                ? moment(this._dayNumberService.convertDayNumberToDate(s.startDayNumber)).format('DD/MM/YYYY')
                : 'None';
          });
        },
        (error) => {
          console.log('Unable to load active sites list: ' + JSON.stringify(error));
          this._languageService.whoops();
          this._mdDialog.hide();
        },
      ),
    );
    promises.push(
      this._fetchDataService.fetchAccountCMUs(this.accountDetail.authAccountId).then(
        (data) => {
          this.groups = (data as Asset[]).filter((s) => s.Status != 'Archived');
        },
        (error) => {
          console.log('Unable to load active groups list: ' + JSON.stringify(error));
          this._languageService.whoops();
          this._mdDialog.hide();
        },
      ),
    );

    this._q.all(promises).then(() => {
      this.siteFilter = this.sites.map((s) => s.siteId);
    });
  }

  public applyGroupFilter(): void {
    this.unselectedChosen = [];
    this.selectAllUnselectedState = false;
    if (this.selectedGroup == null) {
      //Remove Filter
      this.siteFilter = this.sites.map((s) => s.siteId);
      return;
    }

    //Filter to sites belonging to selected group
    this._fetchDataService.fetchCMUSiteAssets(this.accountDetail.authAccountId, [this.selectedGroup.AssetId]).then(
      (data) => {
        this.siteFilter = (data as SiteAsset[]).map((s) => {
          return s.ChildAssetId;
        });
      },
      (error) => {
        console.log('Unable to load group site list: ' + JSON.stringify(error));
        this._languageService.whoops();
        this._mdDialog.hide();
      },
    );
  }

  public checkSelectedSiteStartDates(): fuse.SiteStartDateDto[] {
    if (this.startDate == null) return [];

    const startDayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(this.startDate);
    return this.sites.filter(
      (s) => this.selectedSites.indexOf(s.siteId) >= 0 && (s.startDayNumber == null || s.startDayNumber > startDayNumber),
    );
  }

  public setStep(step: number): void {
    this.step = step;
  }

  public recalculate(): void {
    let htmlContent = '';
    if (this.startType > 0) {
      htmlContent += this._languageService.instant('ADM.ACCOUNT_DETAILS.RECALCULATE_HTML1', { n: this.selectedSites.length });
    }
    htmlContent += this._languageService.instant('ADM.ACCOUNT_DETAILS.RECALCULATE_HTML2', {
      n: this.selectedSites.length,
      d: this.startDateString,
    });

    const problemSites = this.checkSelectedSiteStartDates();
    if (problemSites.length) {
      htmlContent += `<b>${this._languageService.instant('COMMON.WARNING')}</b>&nbsp;`;
      htmlContent +=
        problemSites.length > 1
          ? this._languageService.instant('ADM.ACCOUNT_DETAILS.RECALCULATE_WARNING2', {
              s: problemSites[0].siteName,
              o: problemSites.length - 1,
              d: this.startDateString,
            })
          : this._languageService.instant('ADM.ACCOUNT_DETAILS.RECALCULATE_WARNING1', {
              s: problemSites[0].siteName,
              d: this.startDateString,
            });
      htmlContent += '<br/>';
    }
    htmlContent += '<br/>';
    htmlContent += this._languageService.instant('ADM.ACCOUNT_DETAILS.RECALCULATE_HTML3');
    const confirm = this._mdDialog
      .confirm()
      .title(this._languageService.instant('COMMON.CONFIRM'))
      .htmlContent(htmlContent)
      .parent(angular.element(document.body))
      .ok(this._languageService.instant('COMMON.RECALCULATE'))
      .cancel(this._languageService.instant('COMMON.CANCEL'))
      .multiple(true);
    this._mdDialog.show(confirm).then(() => {
      this.isCalculating = true;
      const dto = {
        adjustment: this.startType != 0 ? this.adjustment : null,
        clearSMBOverride: this.clearSMBOverrides,
        sites: this.selectedSites,
        startDate: moment(this.startDate).format('YYYY-MM-DD'),
        startType: this.startType,
        weatherRollup: this.weatherRollup,
        isRerunKcviCalculation: this.isRerunKcviCalculation,
      } as fuse.recalculateSMBDetailDto;
      this._accountsService.recalculateSMB(this.accountDetail.authAccountId, dto).then(
        () => {
          this.isCalculating = false;
          this._languageService.success('ADM.ACCOUNT_DETAILS.RECALCULATE_SUCCESS');
          this._mdDialog.hide();
        },
        (error) => {
          this.isCalculating = false;
          if (Array.isArray(error.data)) {
            error.data.forEach((msg) => {
              this._languageService.warning(msg, 'COMMON.REQUEST_REJECTED');
            });
          } else {
            this._languageService.warning(error.data, 'COMMON.REQUEST_REJECTED');
          }
        },
      );
    });
  }

  public closeDialog() {
    this._mdDialog.hide();
  }

  get startDateString(): string {
    return this.startDate != null ? moment(this.startDate).format('DD-MMM-YYYY') : '';
  }
}

angular.module('app.administration').controller('AccountRecalculateSMBDialogController', AccountRecalculateSMBDialogController);
