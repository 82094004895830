import * as angular from 'angular';
import * as moment from 'moment';
import { DateUtils } from '@indicina/swan-shared/utils/DateUtils';
import { LanguageService } from '@services/language.service';
import { NotifyEvents, NotifyingService } from '@services/notifying.service';
import { PermissionService } from '@services/permission.service';
import { AccountsService } from '@services/administration/accounts.service';
import { BaseController } from 'src/app/base.controller';

export class AccountTimingsDialogController extends BaseController {
  public title: string;
  private accountDetail: fuse.accountDetail;
  public hourPickList = DateUtils.Locale.getHours24Format();
  public minutePickList = ['00'];
  public shortMonthNames: string[] = DateUtils.Locale.getMonthNamesShort();
  private localStartTimeHour = '00';
  private localStartTimeMin = '00';
  public timeZone: string;
  public timeZoneLookup: fuse.SystemTimeZone[];
  private _userFullName = '';
  private irrigationSeasonStartMonth = 1;

  private _mdDialog: angular.material.IDialogService;
  private _accountsService: AccountsService;
  private _languageService: LanguageService;
  private _notifyingService: NotifyingService;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    AccountsService: AccountsService,
    LanguageService: LanguageService,
    NotifyingService: NotifyingService,
    PermissionService: PermissionService,
    account: fuse.accountDetail,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._accountsService = AccountsService;
    this._languageService = LanguageService;
    this._notifyingService = NotifyingService;

    this.title = this._languageService.instant('ADM.ACCOUNT_DETAILS.CHANGE_TIMINGS');
    this.accountDetail = angular.copy(account);

    const starttime = this.accountDetail.irrigationLocalStartTime;
    this.localStartTimeHour = starttime.substring(0, 2);
    this.localStartTimeMin = starttime.substring(3, 5);
    this.irrigationSeasonStartMonth = this.accountDetail.irrigationSeasonStartMonth == 0 ? 7 : this.accountDetail.irrigationSeasonStartMonth;
    this.timeZone = this.accountDetail.defaultTZStandardName;
    this.timeZoneLookup = [];
    this._userFullName = PermissionService.fullName;

    this.fetchData();
  }

  private fetchData() {
    this._accountsService.getAllTimeZones().then((results) => {
      this.timeZoneLookup = results;
    });
  }

  public saveTimings() {
    // First show the WARNING!
    if (this.scope['accountTimingsForm'].$dirty) {
      const confirm = this._mdDialog
        .confirm()
        .title(this._languageService.instant('COMMON.WARNING'))
        .htmlContent(this._languageService.instant('ADM.ACCOUNT_DETAILS.TIMINGS_WARNING'))
        .multiple(true)
        .ariaLabel(this._languageService.instant('COMMON.WARNING'))
        .ok(this._languageService.instant('COMMON.YES'))
        .cancel(this._languageService.instant('COMMON.NO'));
      this._mdDialog.show(confirm).then(
        () => {
          const timings = {
            DefaultTZId: this.timeZone,
            IrrigationLocalStartTimeHr: parseInt(this.localStartTimeHour),
            IrrigationLocalStartTimeMin: parseInt(this.localStartTimeMin),
            IrrigationSeasonStartMonth: this.irrigationSeasonStartMonth,
            ChangedText: this._userFullName + ' ' + moment.utc().format('YYYY-MM-DD HH:mm:ss') + ' UTC',
          } as fuse.AccountTimings;
          this._accountsService
            .updateTimings(this.accountDetail.authAccountId, timings)
            .then((results) => {
              this._languageService.success('COMMON.CHANGES_SAVED');

              this._mdDialog.hide({ dataRefreshRequired: true }).then(() => {
                this._notifyingService.notify(NotifyEvents.Admin.AccountUpdate, null);
              });
            })
            .catch((error) => {
              this._languageService.showSaveFailure(error.data.ExceptionMessage);
            });
        },
        () => {
          // NO - close dialog
          this._mdDialog.hide();
          return;
        },
      );
    } else {
      this._mdDialog.hide();
      return;
    }
  }

  public closeDialog() {
    if (this.scope['accountTimingsForm'].$dirty) {
      const confirm = this._languageService.closeDialog();
      this._mdDialog.show(confirm).then(
        () => {
          this._mdDialog.hide();
        },
        () => {
          // user canceled the close - don't close the form
        },
      );
    } else {
      this._mdDialog.hide();
    }
  }
}

angular.module('app.administration').controller('AccountTimingsDialogController', AccountTimingsDialogController);
