import * as angular from 'angular';
import { DateUtils } from '@indicina/swan-shared/utils/DateUtils';
import { ApplicationPrivileges } from '@common/ApplicationPrivileges';
import { SQLErrorCodes } from '@common/enums';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { LanguageService } from '@services/language.service';
import { AccountsService } from '@services/administration/accounts.service';
import { CropService } from '@services/crop.service';
import { DayNumberService } from '@services/day-number.service';
import { BaseController } from 'src/app/base.controller';
import { PermissionService } from '@services/permission.service';
import { AccountViewAccessKeyDialogController } from './accountviewaccesskey-dialog.controller';
import { AccountDefaultsDialogController } from './account-defaults-dialog.controller';
import { AccountDetailsDialogController } from './account-details-dialog.controller';
import { AccountTimingsDialogController } from './account-timings-dialog.controller';
import { CustomUnitDialogController } from './customUnit-dialog.controller';
import { AccountAddExternalApiDialogController } from './accountaddexternalapi-dialog.controller';
import { AccountRecalculateSMBDialogController } from './accountrecalculateSMB-dialog.controller';
import { CollectorProfileDialogController } from './collector-profile/collector-profile-dialog.controller';
import { AccountDefaultsInfoDialogController } from './account-defaults-kcvi-info-dialog.controller';
import { BasicDialogController } from './basic-dialog.controller';
import { ConfigureSitesKcviDialogController } from './kcvi/configureSitesKcvi-dialog.controller';
import { DataCaptureDialogController } from './data-capture/data-capture-dialog.controller';

export class AccountDetailsController extends BaseController {
  public accountDetail: fuse.accountDetail;
  public subscriptionLookup: fuse.subscriptionDto[];
  public timingsChangeHistory: string[];
  public isSuperUser = false;
  public externalApiList: fuse.externalApiDto[] = [];
  public dataCollectors: fuse.dataCollectorProfileDto[];
  public irrigationSeasonMonth: string;
  public fallowCropName: string;
  public accountCurrentDate: Date;
  public accountIrrigationDate: Date;
  public isPageReady = false;
  public customUnits: fuse.customUnitDto[];
  public unitContexts: fuse.unitBaseContext[];
  public theProject: { role: 'Supervisor'};

  private _http: angular.IHttpService;
  private _mdDialog: angular.material.IDialogService;
  private _q: angular.IQService;
  private _accountService: AccountsService;
  private _cropService: CropService;
  private _dayNumberService: DayNumberService;
  private _languageService: LanguageService;

  private scheduledSites = [] as fuse.ScheduledSite[];
  private crops: Swan.Crop[];

  constructor(
    $http: angular.IHttpService,
    $mdDialog: angular.material.IDialogService,
    $q: angular.IQService,
    $scope: angular.IScope,
    AccountsService: AccountsService,
    CropService: CropService,
    DayNumberService: DayNumberService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
  ) {
    super(
      $scope,
      PermissionService,
    );
    this.setEditPermission(ApplicationPrivileges.AdminAccountFull);

    this._http = $http;
    this._mdDialog = $mdDialog;
    this._q = $q;
    this._accountService = AccountsService;
    this._cropService = CropService;
    this._dayNumberService = DayNumberService;
    this._languageService = LanguageService;

    this.isSuperUser = PermissionService.isSuperUser;
  }

  $onInit() {
    const adjustedTodayDayNumber = this._dayNumberService.convertBrowserDateTimeToLocaleDayNumber();

    this.accountCurrentDate = this._dayNumberService.convertBrowserDateToLocaleDate(new Date(), this.account.timezoneId); // today - now
    this.accountIrrigationDate = this._dayNumberService.convertDayNumberToDate(adjustedTodayDayNumber);

    const promises = [] as angular.IPromise<void>[];

    promises.push(this.getAccountDetails());
    promises.push(this.getApiList());
    promises.push(this.getDataCollectors());
    promises.push(this.getCrops());
    promises.push(this.getAllSubscriptions());
    promises.push(this.getScheduledSites());
    promises.push(this.getCustomUnits());
    promises.push(this.getUnitContexts());

    this._q.all(promises).then(() => {
      this.setFallowCrop();
      this.isPageReady = true;
    });
  }

  private setFallowCrop() {
    const fallowCrop = this.crops.find((c) => c.Id == this.accountDetail.fallowCropId);
    if (fallowCrop != null) {
      if (fallowCrop.IsActive) {
        this.fallowCropName = fallowCrop.Name;
      } else {
        this.fallowCropName = `${fallowCrop.Name} (${this._languageService.instant('COMMON.ARCHIVED')})`;
      }
    } else {
      this.fallowCropName = this._languageService.instant('COMMON.NOT_SET');
    }
  }

  private getAccountDetails(): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    this._accountService.getSystemAccountById(this.accountId).then(
      (result) => {
        this.accountDetail = result as fuse.accountDetail;
        if (this.accountDetail.timingsChangeHistory != null) {
          const arr = this.accountDetail.timingsChangeHistory.split(';');
          arr.splice(-1, 1);
          this.timingsChangeHistory = arr;
        }

        this.irrigationSeasonMonth = DateUtils.Locale.getMonthNameShort(this.accountDetail.irrigationSeasonStartMonth);
        defer.resolve();
      },
      () => {
        defer.reject();
      },
    );
    return defer.promise;
  }

  private getCrops(): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    this._cropService.getLocalCrop(this.accountId).then((result) => {
      this.crops = result;
      defer.resolve();
    });
    return defer.promise;
  }

  private getCustomUnits() {
    const defer = this._q.defer<void>();
    this._accountService.getCustomUnits().then((results) => {
      this.customUnits = results;
      defer.resolve();
    });
    return defer.promise;
  }

  private getUnitContexts() {
    const defer = this._q.defer<void>();
    this._accountService.getUnitContexts().then((results) => {
      this.unitContexts = results;
      defer.resolve();
    });
    return defer.promise;
  }

  private getAllSubscriptions(): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    this._accountService.getAllSubscriptions().then((results) => {
      this.subscriptionLookup = results;
      defer.resolve();
    });
    return defer.promise;
  }

  private getScheduledSites(): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    this._accountService.getScheduledSites(this.accountId).then((result) => {
      this.scheduledSites = result;
      defer.resolve();
    });
    return defer.promise;
  }

  public hasSubscription(subscriptionName: string): boolean {
    if (this.accountDetail) {
      return this.accountDetail.subscriptions.some((a) => a.name == subscriptionName);
    } else {
      return false;
    }
  }

  public uploadAttachmentFiles(file: File): void {
    if (file) {
      this._accountService
        .uploadLogo(this.accountId, file)
        .then(() => {
          this._languageService.success('COMMON.CHANGES_SAVED');
          this.getAccountDetails(); //refresh account details
          this.scope.$broadcast('accountLogoUpdated');
        })
        .catch((error) => {
          if (typeof error.data?.Message === 'string') {
            this._languageService.error(error.data.Message);
          } else {
            error.data?.ErrorMessages?.forEach((msg) => {
              this._languageService.warning(msg)
            });
          }
        });
    }
  }

  public removeLogo() {
    this._accountService
      .removeLogo(this.accountId)
      .then(() => {
        this._languageService.showSaveSuccess();
        this.getAccountDetails(); //refresh account details
        this.scope.$broadcast('accountLogoUpdated');
      })
      .catch((error) => {
        error.data?.ErrorMessages?.forEach((msg) => {
          this._languageService.warning(msg)
        });
      });
  }

  public editDetails(ev: MouseEvent) {
    this._mdDialog
      .show({
        controller: AccountDetailsDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/administration/views/account-details/account-details-dialog.html',
        parent: angular.element(document.body),
        targetEvent: ev,
        locals: {
          account: this.accountDetail,
        },
      })
      .then((res) => {
        if (res) {
          this.getAccountDetails();
        }
      });
  }

  public editTimings(ev: MouseEvent) {
    this._mdDialog
      .show({
        controller: AccountTimingsDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/administration/views/account-details/account-timings-dialog.html',
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: false,
        locals: {
          account: this.accountDetail,
        },
      })
      .then((returnData) => {
        if (returnData) {
          if (returnData.dataRefreshRequired) {
            this.getAccountDetails(); //refresh the list of users
          }
        }
      });
  }

  public openAccountDefaultsDialog() {
    this._mdDialog
      .show({
        controller: AccountDefaultsDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/administration/views/account-details/account-defaults-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        locals: {
          account: this.accountDetail,
          crops: this.crops,
        },
      })
      .then((returnData) => {
        if (returnData) {
          if (returnData.refreshRequired) {
            this.getAccountDetails().then(() => this.setFallowCrop()); //refresh the account data
          }
        }
      });
  }

  private getApiList(): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    this._accountService.getExternalApiList(this.accountId).then((results) => {
      this.externalApiList = results;
      defer.resolve();
    });
    return defer.promise;
  }

  public customUnitDialog(customUnit: fuse.customUnitDto = null) {
    if (!this.apf.hasCustomUnitsFull) return;

    this._mdDialog
      .show({
        controller: CustomUnitDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/administration/views/account-details/customUnit-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        locals: {
          unitContexts: this.unitContexts,
          customUnit: customUnit,
        },
      })
      .then((returnData) => {
        if (returnData) {
          this._accountService.saveCustomUnits(returnData).then(
            (r) => {
              this._languageService.showSaveSuccess();
              this.getCustomUnits();
            },
            (error) => {
              this._languageService.showSaveFailure(error);
            },
          );
        }
      });
  }

  public deleteCustomUnit(unit: fuse.customUnitDto) {
    const confirm = this._languageService
      .confirm()
      .title('ADM.CUSTOM_UNITS.DELETE_UNIT')
      .htmlContent('ADM.CUSTOM_UNITS.CONFIRM_DELETE')
      .cancel('COMMON.NO')
      .ok('COMMON.YES');
    this._languageService.show(confirm).then(() => {
      this._accountService.deleteCustomUnit(unit).then(
        () => {
          this._languageService.success('ADM.CUSTOM_UNITS.DELETE_SUCCESS');
          this.getCustomUnits();
        },
        (error) => {
          if (error.data.Message == SQLErrorCodes.ConstraintCheckError)
            this._languageService.error('ADM.CUSTOM_UNITS.DELETE_FAILED');
          else this._languageService.whoops();
        },
      );
    });
  }

  public addExternalApi(ev: MouseEvent) {
    this._mdDialog
      .show({
        controller: AccountAddExternalApiDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/administration/views/account-details/accountaddexternalapi-dialog.html',
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: false,
        locals: {
          account: this.accountDetail,
        },
      })
      .then((returnData) => {
        if (returnData) {
          if (returnData.dataRefreshRequired) {
            this.getApiList();
          }
        }
      });
  }

  public deleteExternalApi(externalApiDto: fuse.externalApiDto) {
    const confirm = this._mdDialog
      .confirm()
      .title(this._languageService.instant('COMMON.CONFIRM_DELETE'))
      .htmlContent(
        `<div style="width:300px"><b>` +
          this._languageService.instant('COMMON.WARNING') +
          `</b> – ` +
          this._languageService.instant('ADM.ACCOUNT_DETAILS.API_WARNING') +
          ` <b>${externalApiDto.apiGrantTo}</b>?` +
          `</div>`,
      )
      .ariaLabel(this._languageService.instant('COMMON.CANCEL'))
      .cancel(this._languageService.instant('COMMON.NO'))
      .ok(this._languageService.instant('COMMON.YES_DELETE'));
    this._mdDialog.show(confirm).then((returnData) => {
      if (returnData) {
        this._accountService
          .deleteExternalApi(externalApiDto.id)
          .then((response) => {
            if (response.succeeded == true) {
              this._languageService.success('COMMON.CHANGES_SAVED');
              this.getApiList(); //refresh api list
            } else {
              this._languageService.error(response.message);
            }
          })
          .catch((error) => {
            error.data?.ErrorMessages?.forEach((msg) => {
              this._languageService.warning(msg)
            });
          });
      }
    });
  }

  public viewAccessKey(externalApiInfo: fuse.externalApiDto) {
    this._mdDialog
      .show({
        controller: AccountViewAccessKeyDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/administration/views/account-details/accountviewaccesskey-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        locals: {
          scheduledSites: this.scheduledSites,
          externalApiInfo: externalApiInfo,
        },
      })
      .then(() => {});
  }

  public recalculateSMB(ev: MouseEvent) {
    this._mdDialog.show({
      controller: AccountRecalculateSMBDialogController,
      controllerAs: 'vm',
      templateUrl: 'src/app/pages/administration/views/account-details/accountrecalculateSMB-dialog.html',
      parent: angular.element(document.body),
      targetEvent: ev,
      clickOutsideToClose: false,
      locals: {
        account: this.accountDetail,
      },
    });
  }

  private getDataCollectors(): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    this._http
      .get(CommonHelper.getApiUrl('administration/getDataCollectors'))
      .then((response) => {
        if (response.data == null) {
          this._languageService.whoops();
        } else {
          this.dataCollectors = response.data as fuse.dataCollectorProfileDto[];
        }
        defer.resolve();
      })
      .catch(() => {});
    return defer.promise;
  }

  public openAddDataCollectorDialog() {
    this._mdDialog
      .show({
        controller: CollectorProfileDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/administration/views/account-details/collector-profile/collector-profile-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        locals: {
          dataCollector: null,
          dataCollectors: this.dataCollectors,
        },
      })
      .then((response) => {
        if (response) {
          this.getDataCollectors();
        }
      });
  }

  public openEditDataCollectorDialog(row: fuse.dataCollectorProfileDto) {
    this._mdDialog
      .show({
        controller: CollectorProfileDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/administration/views/account-details/collector-profile/collector-profile-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        locals: {
          dataCollector: row,
          dataCollectors: this.dataCollectors,
        },
      })
      .then((response) => {
        if (response) {
          this.getDataCollectors();
        }
      });
  }

  public deleteDataCollector(row: fuse.dataCollectorProfileDto) {
    const confirm = this._mdDialog
      .confirm()
      .title(this._languageService.instant('ADM.ACCOUNT_DETAILS.REMOVE_COLLECTOR'))
      .htmlContent(this._languageService.instant('ADM.ACCOUNT_DETAILS.REMOVE_COLLECTOR_CONFIRM', { name: row.name }))
      .cancel(this._languageService.instant('COMMON.NO'))
      .ok(this._languageService.instant('COMMON.YES'));
    this._mdDialog.show(confirm).then(() => {
      this._http
        .delete(CommonHelper.getApiUrl('administration/deleteDataCollector/' + row.id))
        .then((_response) => {
          this._languageService.success('ADM.ACCOUNT_DETAILS.DATA_COLLECTOR_REMOVED');
          this.getDataCollectors();
        })
        .catch(() => {
          this._languageService.whoops();
        });
    });
  }

  public openAccountDefaultsKcviHelpDialog() {
    this._mdDialog
      .show({
        controller: AccountDefaultsInfoDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/administration/views/account-details/account-defaults-kcvi-info-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
      })
      .then(() => {});
  }

  public openForecastAdjustHelpDialog() {
    this._mdDialog
      .show({
        controller: BasicDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/administration/views/account-details/basic-dialog.html',
        parent: angular.element(document.body),
        locals: { title: 'ADM.ACCOUNT_DETAILS.FORECAST_ADJUSTMENT', body: 'ADM.ACCOUNT_DETAILS.FORECAST_ADJUST_HELP' },
        clickOutsideToClose: false,
      })
      .then(() => {});
  }

  public openAccountDefaultsFallowDialog() {
    this._mdDialog
      .show({
        controller: AccountDefaultsInfoDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/administration/views/account-details/account-defaults-fallow-info-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
      })
      .then(() => {});
  }

  public openAccountDefaultsHealthDialog() {
    this._mdDialog
      .show({
        controller: AccountDefaultsInfoDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/administration/views/account-details/account-defaults-health-info-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
      })
      .then(() => {});
  }

  public openConfigureSitesKcviDialog() {
    this._mdDialog
      .show({
        controller: ConfigureSitesKcviDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/administration/views/account-details/kcvi/configureSitesKcvi-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
      })
      .then(() => {});
  }

  public openDataCaptureDialog() {
    this._mdDialog
      .show({
        controller: DataCaptureDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/administration/views/account-details/data-capture/data-capture-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
      })
      .then(() => {});
  }
}

angular.module('app.administration').controller('AccountDetailsController', AccountDetailsController);
