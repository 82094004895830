import * as angular from 'angular';
import { InitialisationService } from '@services/initialisation.service';
import { PermissionService } from '@services/permission.service';
import { BaseController } from 'src/app/base.controller';

export class AccountDefaultsInfoDialogController extends BaseController {
  private _mdDialog: angular.material.IDialogService;

  public key: string;
  public reliabilityThresholds: string;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    PermissionService: PermissionService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;

    this.reliabilityThresholds = `assets/images/etc/Reliability-Threshold_${InitialisationService.selectedLanguageCode}.png`;
  }

  public close() {
    this._mdDialog.hide();
  }
}

angular.module('app.administration').controller('AccountDefaultsInfoDialogController', AccountDefaultsInfoDialogController);
