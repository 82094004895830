import * as angular from 'angular';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { BaseController } from 'src/app/base.controller';

export class DataCaptureDialogController extends BaseController {
  private _http: angular.IHttpService;
  private _mdDialog: angular.material.IDialogService;
  private _languageService: LanguageService;

  public accountAssets: fuse.equipmentProfileDto[] = [];
  public step = 0;
  public selectedAssets = [] as fuse.equipmentProfileDto[];
  public dataCaptureParams = {} as fuse.dataCaptureDto;

  constructor(
    $http: angular.IHttpService,
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._http = $http;
    this._mdDialog = $mdDialog;
    this._languageService = LanguageService;
  }

  $onInit() {
    this._http.get(CommonHelper.getApiUrl('administration/getAccountAssets')).then((result) => {
      this.accountAssets = result.data as fuse.equipmentProfileDto[];
    });
  }

  public closeDialog() {
    this._mdDialog.hide();
  }

  public onSelectedAssetsChanged(selectedAssets: fuse.equipmentProfileDto[]): void {
    this.selectedAssets = selectedAssets;
  }

  public onOptionChanged(dataCaptureOption: fuse.dataCaptureDto): void {
    this.dataCaptureParams.isIntervalMode = dataCaptureOption.isIntervalMode;
    this.dataCaptureParams.endDateYMD = dataCaptureOption.endDateYMD;
    this.dataCaptureParams.fromDateYMD = dataCaptureOption.fromDateYMD;
    this.dataCaptureParams.runDays = dataCaptureOption.runDays;
  }

  public back(): void {
    this.step--;
  }

  public next(): void {
    this.step++;
  }

  public run() {
    this.dataCaptureParams.assetIds = this.selectedAssets.map((a) => a.assetId);
    this._http.post(CommonHelper.getApiUrl('scheduler/runDataCapture'), this.dataCaptureParams).then((res) => {
      if (res) {
        this._mdDialog.hide();
        this._languageService.info('ADM.ACCOUNT_DETAILS.CAPTURE_INITIATED');
      } else {
        this._languageService.whoops();
      }
    });
  }
}

angular.module('app.administration').controller('DataCaptureDialogController', DataCaptureDialogController);
