// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#configure-kcvi-options-id md-checkbox {
  margin-bottom: 0;
}
#configure-kcvi-options-id md-input-container {
  margin: 0 0 8px;
  padding: 0;
}
#configure-kcvi-options-id md-input-container .md-errors-spacer {
  min-height: 0px !important;
}
#configure-kcvi-options-id md-input-container md-datepicker {
  margin: 0;
}
#configure-kcvi-options-id md-input-container md-datepicker .md-datepicker-input {
  width: 220px;
}
#configure-kcvi-options-id .md-button.md-mini-fab {
  padding: 0px;
  margin: 4px;
  width: 24px;
  height: 24px;
  min-height: 24px;
}
#configure-kcvi-options-id table thead tr th {
  background-color: #666;
  color: #fff;
  text-align: center;
  padding-top: 2px;
  padding-bottom: 2px;
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  top: 0;
  z-index: 10;
}
#configure-kcvi-options-id table tbody tr td {
  text-align: center;
  padding-top: 2px;
  padding-bottom: 2px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/administration/views/account-details/kcvi/configureKcvi-options.scss"],"names":[],"mappings":"AACI;EACI,gBAAA;AAAR;AAEI;EACI,eAAA;EACA,UAAA;AAAR;AACQ;EAEI,0BAAA;AAAZ;AAEQ;EACI,SAAA;AAAZ;AACY;EACI,YAAA;AAChB;AAGI;EACI,YAAA;EACA,WAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;AADR;AAIQ;EACI,sBAAA;EACA,WAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,wBAAA,EAAA,eAAA;EACA,gBAAA;EACA,MAAA;EACA,WAAA;AAFZ;AAIQ;EACI,kBAAA;EACA,gBAAA;EACA,mBAAA;AAFZ","sourcesContent":["#configure-kcvi-options-id {\r\n    md-checkbox {\r\n        margin-bottom: 0;\r\n    }\r\n    md-input-container {\r\n        margin: 0 0 8px;\r\n        padding: 0;\r\n        .md-errors-spacer {\r\n            //display: inline;\r\n            min-height: 0px !important;\r\n        }\r\n        md-datepicker {\r\n            margin: 0;\r\n            .md-datepicker-input {\r\n                width: 220px;\r\n            }\r\n        }\r\n    }\r\n    .md-button.md-mini-fab {\r\n        padding: 0px;\r\n        margin: 4px;\r\n        width: 24px;\r\n        height: 24px;\r\n        min-height: 24px;\r\n    }\r\n    table {\r\n        thead tr th {\r\n            background-color: #666;\r\n            color: #fff;\r\n            text-align: center;\r\n            padding-top: 2px;\r\n            padding-bottom: 2px;\r\n            position: -webkit-sticky; /* for Safari */\r\n            position: sticky;\r\n            top: 0;\r\n            z-index: 10;\r\n        }\r\n        tbody tr td {\r\n            text-align: center;\r\n            padding-top: 2px;\r\n            padding-bottom: 2px;\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
