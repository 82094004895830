import * as angular from 'angular';
import { PermissionService } from '@services/permission.service';
import { BaseController } from 'src/app/base.controller';

export class CustomUnitDialogController extends BaseController {
  private _mdDialog: angular.material.IDialogService;

  public apiList: string[];
  public apiPartnerList: string[];
  public apiPermissionTypeList: string[];
  public apiName: string;
  public apiPartner: string;
  public apiPermission: string;
  public apiReference: string;
  public customUnit: fuse.customUnitDto;
  public editMode: boolean = false;
  public editConfirmed: boolean = true;
  public title = 'CREATE';
  public editClass: string = '';
  public showAlert = false;

  public unitContexts: fuse.unitBaseContext[];
  public selectedContext: fuse.unitBaseContext;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    PermissionService: PermissionService,
    unitContexts: fuse.unitBaseContext[],
    customUnit: fuse.customUnitDto,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;

    this.apiList = [];
    this.apiPartnerList = [];
    this.apiPermissionTypeList = [];
    this.unitContexts = unitContexts;

    this.customUnit = angular.copy(customUnit); // allows us to cancel changes without affecting original obj
    if (this.customUnit == null) {
      // Add new mode
      this.customUnit = {} as fuse.customUnitDto;

      //Currently only base unit option is yield, so set by default
      this.selectedContext = this.unitContexts[0];
      this.customUnit.context = this.selectedContext.name;
      this.customUnit.baseClassId = this.selectedContext.id;
    } else {
      // Edit mode
      const derivedFrom = this.customUnit.derivedFromUnit;
      this.setContext();

      this.editMode = true;
      this.editConfirmed = false;
      this.title = 'EDIT';
      this.editClass = 'rounded-blue';
      // make sure object attached to customUnit === object from list of units attached to the context object - useful for md-select
      this.customUnit.derivedFromUnit = this.selectedContext.units.find((u) => u.unitId == derivedFrom.unitId);
    }
    this.title = 'ADM.CUSTOM_UNITS.' + this.title;
  }

  public setContext() {
    this.selectedContext = this.unitContexts.find((c) => c.id == this.customUnit.baseClassId);
    this.customUnit.context = this.selectedContext.name;
    this.customUnit.derivedFromUnit = null;
  }

  public saveCustomUnit() {
    this._mdDialog.hide(this.customUnit);
  }

  public closeDialog() {
    this._mdDialog.hide();
  }

  public changed() {
    this.showAlert = this.editMode;
  }
}

angular.module('app.administration').controller('AccountAddExternalApiDialogController', CustomUnitDialogController);
