import * as angular from 'angular';
import { AccountsService } from '@services/administration/accounts.service';
import { TableControl } from '@common/helpers/TableControl';
import { AccountDialogController } from './account-dialog.controller';

export class AccountsController {
  private _mdDialog: angular.material.IDialogService;
  private _filter: angular.IFilterService;
  private _accountsService: AccountsService;

  public accounts: fuse.accountDetail[];
  public filteredAccounts: fuse.accountDetail[];
  public showDisabled = false;
  public subscriptionLookup = [] as fuse.subscriptionDto[];

  public tableControl = new TableControl({ searchField: 'companyName' });

  constructor(
    $mdDialog: angular.material.IDialogService,
    $filter: angular.IFilterService,
    AccountsService: AccountsService,
  ) {
    this._mdDialog = $mdDialog;
    this._filter = $filter;
    this._accountsService = AccountsService;
  }

  $onInit() {
    this._fetchData();
  }

  private _fetchData() {
    this._accountsService.getAllSubscriptions().then((results) => {
      this.subscriptionLookup = results;
    });
    this._accountsService.getAllSystemAccounts().then((results) => {
      this.accounts = results;
      this.applyArchivedFilter();
    });
  }

  private applyArchivedFilter() {
    if (!this.showDisabled) {
      this.filteredAccounts = this._filter('filter')(this.accounts, { status: '!Archived' } as fuse.accountDetail);
    } else {
      this.filteredAccounts = this.accounts;
    }
  }

  public hasSubscription(account: fuse.accountDetail, subscriptionName: string): boolean {
    if (account) {
      const result = this._filter('filter')(account.subscriptions, { name: subscriptionName } as fuse.subscriptionDto);

      return !!result.length;
    } else {
      return false;
    }
  }

  public openAccountDialog(ev: MouseEvent, account: fuse.accountDetail) {
    this._mdDialog
      .show({
        controller: AccountDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/administration/views/system/accounts/account-dialog.html',
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: false,
        locals: {
          account: account,
        },
      })
      .then((returnData) => {
        if (returnData) {
          if (returnData.dataRefreshRequired) {
            this._fetchData(); //refresh the list of users
          }
        }
      });
  }
}

angular.module('app.administration').controller('AccountsController', AccountsController);
