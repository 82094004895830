import * as angular from 'angular';
import { AccountsService } from '@services/administration/accounts.service';
import { PermissionService } from '@services/permission.service';
import { BaseController } from 'src/app/base.controller';

export class RolePrivilegeDialogController extends BaseController {
  public title: string;
  public rolePrivileges: fuse.rolePrivilegeDto;

  private _mdDialog: angular.material.IDialogService;
  private _toastr: Toastr;
  private _accountService: AccountsService;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    AccountsService: AccountsService,
    PermissionService: PermissionService,
    toastr: Toastr,
    rolePrivileges: fuse.rolePrivilegeDto,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._accountService = AccountsService;
    this._toastr = toastr;

    this.title = 'Update Role Privileges: ' + rolePrivileges.role;
    this.rolePrivileges = rolePrivileges;
  }

  public updateRolePrivileges() {
    this._accountService.updateRolePrivileges(this.rolePrivileges).then((results) => {
      this._toastr.info(this.rolePrivileges.role + ' privileges is updated', 'Success');
      this._mdDialog.hide({ dataRefreshRequired: true });
    });
  }

  public closeDialog() {
    if (this.scope['updateRolePrivilegesForm'].$dirty) {
      const confirm = this._mdDialog
        .confirm()
        .title('Close?')
        .htmlContent('It looks like you have unsaved changes. Close anyway?')
        .multiple(true)
        .ok('CLOSE ANYWAY')
        .cancel('NO, GO BACK');
      this._mdDialog.show(confirm).then(
        () => {
          this._mdDialog.hide({ dataRefreshRequired: false });
        },
        () => {},
      );
    } else {
      this._mdDialog.hide({ dataRefreshRequired: false });
    }
  }
}

angular.module('app.water').controller('RolePrivilegeDialogController', RolePrivilegeDialogController);
