import * as angular from 'angular';
import { AppSettings } from '@indicina/swan-shared/AppSettings';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { AuthZeroService } from '@services/auth-zero.service';
import { LocalStorageService } from '@services/local-storage.service';
import { UsersService } from '@services/administration/users.service';

export class LoginController {
  public appConfig = AppSettings.app;
  public route: string;
  public announcement: string;
  public checked = false;
  public isAnnouncement = false;
  public isApiError = false;
  public isAuthError = false;
  public isOrphanUser = false;
  public isUnacceptedTC = false;
  public isWrongVersion = false;

  private _rootScope: angular.IRootScopeService;
  private _state: angular.ui.IStateService;
  private _authZeroService: AuthZeroService;
  private _localStorageService: LocalStorageService;
  private _usersService: UsersService;

  constructor(
    $rootScope: angular.IRootScopeService,
    $state: angular.ui.IStateService,
    AuthZeroService: AuthZeroService,
    LocalStorageService: LocalStorageService,
    UsersService: UsersService,
  ) {
    this._rootScope = $rootScope;
    this._state = $state;
    this._authZeroService = AuthZeroService;
    this._localStorageService = LocalStorageService;
    this._usersService = UsersService;

    this.route = $state?.params?.route;
    this.announcement = $state?.params?.announcement;
    this.isAnnouncement = !!$state?.params?.announcement;
    this.isApiError = $state?.params?.isApiError;
    this.isAuthError = $state?.params?.isAuthError;
    this.isOrphanUser = $state?.params?.isOrphanUser;
    this.isUnacceptedTC = $state?.params?.isUnacceptedTC;
    this.isWrongVersion = $state?.params?.isWrongVersion;

    const hasErrors =
      this.isAnnouncement ||
      this.isApiError ||
      this.isAuthError ||
      this.isOrphanUser ||
      this.isUnacceptedTC ||
      this.isWrongVersion;

    if (!hasErrors) {
      this._state.go('app.account.dashboard');
    }
  }

  $onInit() {
    CommonHelper.UI.SplashScreen.remove(this._rootScope);
  }

  public acceptTerms() {
    this._usersService.acceptTerms().then(() => {
      window.open(`${CommonHelper.baseRoute}${this.route ?? ''}`, '_self');
    }).catch(() => {
      //this.logout();
    });
  }

  public continue() {
    if (this.checked) {
      this._localStorageService.set('announcementDismissedDate', new Date().toISOString());
    }

    const targetState = this._state.get().find(s => s.url === `/${this.route}`);

    this._state.go(targetState?.name ?? 'app.account.dashboard');
  }

  public logout() {
    this._authZeroService.logout();
  }
}

angular.module('app.auth').controller('LoginController', LoginController);
