import * as angular from 'angular';
import { ApplicationPrivileges } from '@common/ApplicationPrivileges';
import { SWANConstants } from '@common/SWANConstants';
import { AccountsService } from '@services/administration/accounts.service';
import { AuthZeroService } from '@services/auth-zero.service';
import { LanguageService } from '@services/language.service';
import { NotifyEvents, NotifyingService } from '@services/notifying.service';
import { PermissionService } from '@services/permission.service';
import { UsersService } from '@services/administration/users.service';
import { BaseController } from 'src/app/base.controller';

export class AccountUserDialogController extends BaseController {
  private _mdDialog: angular.material.IDialogService;
  private _accountsService: AccountsService;
  private _languageService: LanguageService;
  private _notifyingService: NotifyingService;
  private _usersService: UsersService;

  public title: string;
  public contact: fuse.AccountUser;
  public secureRoles: string[];
  public newUser: boolean;
  private action: string;
  public isCurrentUser: boolean;
  public enableAddUserButton = false;
  public isSaving: boolean;
  private premixedUomName: string;
  private languageCode: string;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    AccountsService: AccountsService,
    AuthZeroService: AuthZeroService,
    LanguageService: LanguageService,
    NotifyingService: NotifyingService,
    PermissionService: PermissionService,
    UsersService: UsersService,
    item: fuse.AccountUser,
    action: string,
    premixedUomName: string,
    languageCode: string,
  ) {
    super(
      $scope,
      PermissionService,
    );
    this.setEditPermission(ApplicationPrivileges.AdminUsersFull);

    this._mdDialog = $mdDialog;
    this._accountsService = AccountsService;
    this._languageService = LanguageService;
    this._notifyingService = NotifyingService;
    this._usersService = UsersService;

    this.title = this._languageService.instant('ADM.USER.TITLE_EDIT');
    this.isCurrentUser = false;
    this.newUser = false;
    this.secureRoles = SWANConstants.UserRoles;
    this.action = action;
    this.premixedUomName = premixedUomName;
    this.languageCode = languageCode ?? item.languageCode;
    this.contact = angular.copy(item);

    if (this.action == 'create') {
      this.title = this._languageService.instant('ADM.USER.TITLE_ADD');
      this.newUser = true;
    } else {
      this.isCurrentUser = AuthZeroService.getEmail() == item.Email; // Note: if user is the current authenticated user then role can't be edited
    }
    if (this.isReadOnly) {
      this.title = this._languageService.instant('ADM.USER.TITLE_VIEW');
    }

    this.scope.$watchGroup(['vm.contact.PhoneNumber', 'vm.contact.ContactMobileNumber'], () => {
      if (this.contact.PhoneNumber && this.contact.PhoneNumber.trim() !== '') {
        if (!this.contact.ContactMobileNumber || this.contact.ContactMobileNumber.trim() === '') {
          this.contact.ContactMobileNumber = ' ';
        }
      }
      if (this.contact.ContactMobileNumber && this.contact.ContactMobileNumber.trim() !== '') {
        if (!this.contact.PhoneNumber || this.contact.PhoneNumber.trim() === '') {
          this.contact.PhoneNumber = ' ';
        }
      }

      if (
        (!this.contact.ContactMobileNumber || this.contact.ContactMobileNumber.trim() === '') &&
        (!this.contact.PhoneNumber || this.contact.PhoneNumber.trim() === '')
      ) {
        this.contact.PhoneNumber = '';
        this.contact.ContactMobileNumber = '';
      }
      this._validForm();
    });
    this.scope.$watchGroup(['vm.contact.FirstName', 'vm.contact.Email'], () => {
      this._validForm();
    });
  }

  public addNewUser() {
    const confirm = this._mdDialog
      .confirm()
      .title(this._languageService.instant('ADM.USER.TITLE_ADD'))
      .htmlContent(this._languageService.instant('ADM.USER.CONFIRM_ADD') + '<br/> <br/>' + this.contact.Email)
      .multiple(true)
      .ok(this._languageService.instant('COMMON.YES'))
      .cancel(this._languageService.instant('COMMON.NO'));
    this._mdDialog.show(confirm).then(() => {
      this.isSaving = true;
      this.contact.premixedUomName = this.premixedUomName;
      this.contact.languageCode = this.languageCode;
      this._accountsService.createAccountUser(this.contact)
        .then(() => {
          this._languageService.success('ADM.USER.USER_ADDED');
          this._notifyingService.notify(NotifyEvents.Admin.UserUpdate, null);
          this._mdDialog.hide();
        })
        .catch((error) => {
          if (error.data) {
            this._languageService.error(error.data);
          } else {
            this._languageService.whoops();
          }
        })
        .finally(() => {
          this.isSaving = false;
        });
    });
  }

  public saveUser() {
    this.isSaving = true;
    this._accountsService.updateAccountUser(this.contact)
      .then(() => {
        this._languageService.showSaveSuccess();
        this._notifyingService.notify(NotifyEvents.Admin.UserUpdate, null);
        this._mdDialog.hide();
      })
      .catch((error) => {
        if (error.data) {
          this._languageService.showSaveFailure(error.data);
        }
      })
      .finally(() => {
        this.isSaving = false;
      });
  }

  public closeDialog() {
    if (this.scope['accountUserForm'].$dirty) {
      const confirm = this._languageService.closeDialog();
      this._mdDialog.show(confirm).then(() => {
        this._mdDialog.hide();
      });
    } else {
      this._mdDialog.hide();
    }
  }

  public getRoles() {
    if (this.accountId === 1) {
      return SWANConstants.RoleCodes;
    }
    return SWANConstants.AccountRoles;
  }

  public emailAccountAccess() {
    this._usersService.emailAccountAccess(this.contact);
  }

  private _validForm() {
    if (this.contact.FirstName && this.contact.Email && this.contact.Role && this.contact.PhoneNumber && this.contact.ContactMobileNumber) {
      this.enableAddUserButton = true;
    } else {
      this.enableAddUserButton = false;
    }
  }
}

angular.module('app.administration').controller('AccountUserDialogController', AccountUserDialogController);
