// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#account-defaults-dialog {
  width: 700px;
}
#account-defaults-dialog md-input-container {
  margin: 0 !important;
  padding: 0;
  vertical-align: bottom;
}
#account-defaults-dialog md-input-container .md-errors-spacer {
  min-height: 0px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/administration/views/account-details/account-defaults-dialog.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;AAAI;EACI,oBAAA;EACA,UAAA;EACA,sBAAA;AAER;AADQ;EACI,0BAAA;AAGZ","sourcesContent":["#account-defaults-dialog {\r\n    width: 700px;\r\n    md-input-container {\r\n        margin: 0 !important;\r\n        padding: 0;\r\n        vertical-align: bottom;\r\n        .md-errors-spacer {\r\n            min-height: 0px !important;\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
